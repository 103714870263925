import { useEffect, useState } from "react";
import API from "../API";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
function Dashboard() {

  const location = useLocation();
  const navigate = useNavigate();
  const [colleges, setColleges] = useState([]);
  const [university, setUniversity] = useState([]);
  const [collegeNotice, setCollegeNotice] = useState([]);
  useEffect(() => {
    fetchCollege();
    fetchUniversity();
    fetchCollegeNotice();
  }, [location]);

  const fetchCollege = async () => {
    try {
      const response = await API.get('college/all');
      setColleges(response.data.records);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  }
  const fetchUniversity = async () => {
    try {
      const response = await API.get('university/all');
      setUniversity(response.data.records);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };
  const fetchCollegeNotice = async () => {
    try {
      const response = await API.get('college-notice');
      setCollegeNotice(response.data.records);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };
  return (

    <div className="row align-items-center mb-3">
								<div className="col-12 col-xl-6">
									<h2 className="mb-2">Analytics Dashboard</h2>
									<h6 className="mb-2 fw-light text-dark small">
										A collection of visualizations showing your website data.
									</h6>
								</div>
								<div className="col-12 col-xl-6">
									<div className="row g-3">
										<div className="col-4">
											<div className="card p-2 rounded-2 d-flex flex-row flex-wrap">
												<div className="position-relative shape-block">
													<img src="assets/images/shape1.png" className="img-fluid img-4x" alt="Bootstrap Themes" />
													<i className="icon-book-open"></i>
												</div>
												<div className="ms-2">
													<h3 className="m-0 fw-semibold">{colleges.length}</h3>
													<h6 className="m-0 fw-light">College</h6>
												</div>
											</div>
										</div>
										<div className="col-4">
											<div className="card p-2 rounded-2 d-flex flex-row flex-wrap">
												<div className="position-relative shape-block">
													<img src="assets/images/shape2.png" className="img-fluid img-4x" alt="Bootstrap Themes" />
													<i className="icon-shopping-cart"></i>
												</div>
												<div className="ms-2">
													<h3 className="m-0 fw-semibold">{university.length}</h3>
													<h6 className="m-0 fw-light">University</h6>
												</div>
											</div>
										</div>
										<div className="col-4">
											<div className="card p-2 rounded-2 d-flex flex-row flex-wrap">
												<div className="position-relative shape-block">
													<img src="assets/images/shape3.png" className="img-fluid img-4x" alt="Bootstrap Themes" />
													<i className="icon-shopping-bag"></i>
												</div>
												<div className="ms-2">
													<h3 className="m-0 fw-semibold">{collegeNotice.length}</h3>
													<h6 className="m-0 fw-light">Col. Notice</h6>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
  );
}
export default Dashboard;