import React,{useState,useEffect} from "react";
import { confirmAlert } from 'react-confirm-alert'; 
import { Link,useNavigate,useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import API from "../../API";
import MyDataTable from "../../components/MyDataTable";

const CollegeTypes = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const [collegetype, setCollegeType] = useState([]);

    useEffect(()=>{
        fetchCollegeTypes();
    }, [location]);
    const fetchCollegeTypes = async () => {
        try {
          const response = await API.get('collegetypes');
          setCollegeType(response.data.records);
        } catch (error) {
          console.error('Error fetching users:', error);
        }
    };

    const Delete = async (id,status) => {
        console.log(id);
        //fetchData();
        await API({
            method: 'DELETE',
            url: `collegetypes/${id}/${status}`,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(res => {
          //fetchData();
          //setColleges(colleges.filter((item) => item._id !== id));
          toast.success(res.data.message);
          navigate('/collegetypes');
          //setRerender((prev) => !prev);
        })
    }
    const deleteRecord = (id,status) => {
      console.log(id);
      var StatusMsg = (status==1)?'Inactive':'Active';
      confirmAlert({
        title: 'Confirm to submit',
        message: 'Are you sure to '+ StatusMsg +' this.',
        buttons: [
          {
            label: 'Yes',
            onClick: () => Delete(id,status)
          },
          {
            label: 'No',
            //onClick: () => alert('Click No')
          }
        ]
      });
    }

    const columns = [
      {
        name: 'SNO.',
        selector: (row, index) => index + 1,
        sortable: true,
      },
      {
        name: 'College Type Name',
        selector: row => row.college_type_name,
        sortable: true,
      },
      {
        name: 'Short Name',
        selector: row => row.short_name,
        sortable: true,
      },
      {
        name: 'Action',
        selector: row=>
        <div style={{display:"flex"}}>
        <Link className="form-control" to={`/collegetypes/edit/${row._id}`}>Edit</Link>&nbsp;&nbsp;
        
        {/* <button  onClick={() => deleteRecord(row._id)}>Delete</button> */}
        {row.is_deleted==1?(<button className="form-control" style={{backgroundColor:"red"}} onClick={() => deleteRecord(row._id,0)}>Active</button>):(<button className="form-control" onClick={() => deleteRecord(row._id,1)}>Deactive</button>)}
        </div>
      
    
    
    
      },
    ];
      return (
        <div className="row mt-3">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
                <div className="row">
                    <div className="text-end" ><h5 className="card-title"><Link to="/collegetypes/add">Add New</Link></h5></div>
                </div>
              
              <div className="table-responsive">
              <MyDataTable
                title="College Types List" data={collegetype} columns={columns}
              />
          
            </div>
            </div>
          </div>
        </div>
      </div>
      );
}

export default CollegeTypes