
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Outlet } from 'react-router-dom';
import Sidebar from "./Sidebar";
import Header from './Header';
import Footer from './Footer';



function Layout(){
    return (
      <>
      <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      />
      
      <div className="page-wrapper">
        <div className="main-container">
          <Sidebar />
          <div className="app-container">
            <Header />
                <div className="app-body">
                    <div className="container-fluid">
                    <Outlet/>
                    </div>
                </div>
                <Footer/>
          </div>
        </div>
      </div>
      </>
    );
}
export default Layout;