import * as Yup from 'yup';

import React from 'react'

export const DistrictFormSchema = Yup.object({ 
    country_id:Yup.string().required("Select Country"),
    state_id:Yup.string().required("Select State"),
    district_name:Yup.string().required("Enter district name"),
    short_name:Yup.string().required("Enter short name")
    
});