import { useFormik } from "formik";
import {useState,useEffect} from "react";
import { CollegeTypeFormSchema } from "../validation/CollegeTypeFormSchema";
import {Link, useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import API from "../../API";

function SaveCollegeTypes(){

    const params = useParams();
    const navigate = useNavigate();
    const [collegeTypeDetails, setCollegeTypeDetails] = useState([]);
    useEffect(()=>{
      fetchCollegeTypeData();
    }, []);
    const fetchCollegeTypeData = () => {
      setTimeout(() => {
      }, 3000);
      API.get(`collegetypes/${params.id}`, {
          //   params: {
          //     id: params.id
          //   }
      }).then(({ data }) => {
        setCollegeTypeDetails(data.records);
      }).catch(error => {
          console.log(error);
      })
  }
  
    const formInitialValue = {
      college_type_name: collegeTypeDetails.college_type_name?collegeTypeDetails.college_type_name:'', 
      short_name: collegeTypeDetails.short_name?collegeTypeDetails.short_name:'', 
    }
    const formik = useFormik({
        initialValues : formInitialValue,
        validationSchema: CollegeTypeFormSchema,
        enableReinitialize: true,
        onSubmit:(values) => {
            const collegeTypeData = { 
              college_type_name: values.college_type_name, 
              short_name: values.short_name
            }; 
            if(collegeTypeDetails._id){
                API.put(`collegetypes/${params.id}`, 
                collegeTypeData).then((res) => { 
                   // alert("data inserted successfully");
                //console.log(res.data); 
                toast.success(res.data.message);
                navigate('/collegetypes');
                }).catch((error) => { 
                console.log(error); 
                toast.error("Something went wrong");
                }); 
            }else{
                API.post("collegetypes", 
                  collegeTypeData).then((res) => { 
                    //alert("data inserted successfully");
                    toast.success(res.data.message);
                    navigate('/collegetypes');
                console.log(res.data); 
                }).catch((error) => { 
                console.log(error); 
                toast.error("Something went wrong");
                }); 
            }
            
        }
    });

    return (
        <div className="row mt-3">
            <div className="card mb-3">
                <div className="card-header">
                    <h5 className="card-title">{params.id ? 'Edit ' : 'Add '} Country</h5>
                </div>
                <div className="card-body">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row mb-3">
                            <label for="inputEmail3" className="col-sm-3 col-form-label">College Type Name</label>
                            <div className="col-sm-9">
                            <input type="text"  name="college_type_name" id="college_type_name" className="form-control" onBlur={formik.handleBlur} onChange={formik.handleChange} placeholder="Enter College Type Name" value={formik.values.college_type_name} />
                            {formik.errors.college_type_name && formik.touched.college_type_name?(<span style={{color:"red"}}>{formik.errors.college_type_name}</span>):null}
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label for="inputPassword3" className="col-sm-3 col-form-label">Short Name</label>
                            <div className="col-sm-9">
                            <input type="text"  name="short_name" id="short_name" className="form-control" onBlur={formik.handleBlur} onChange={formik.handleChange} placeholder="Enter Country Short Name" value={formik.values.short_name} />
                            {formik.errors.short_name && formik.touched.short_name?(<span style={{color:"red"}}>{formik.errors.short_name}</span>):null}
                            </div>
                        </div>

                        <button type="submit" className="btn btn-success">Save</button> <Link to="/collegetypes" className="btn btn-outline-light"> Back </Link>
                    </form>
                </div>
            </div>

        </div>
    );
}
export default SaveCollegeTypes;