import React,{useState,useEffect} from "react";
import { confirmAlert } from 'react-confirm-alert'; 
import { Link,useNavigate,useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import API from "../../API";
import MyDataTable from "../../components/MyDataTable";


const Country = () => {
  const location = useLocation();
  const navigate = useNavigate();
    const [country, setCountry] = useState([]);
    useEffect(()=>{
        fetchNotice();
    }, [location]);
    const fetchNotice = async () => {
        try {
          const response = await API.get('country');
          setCountry(response.data.records);
        } catch (error) {
          console.error('Error fetching users:', error);
        }
    };

  
    const Delete = async (id,status) => {
        console.log(id);
        //fetchData();
        await API({
            method: 'DELETE',
            url: `country/${id}/${status}`,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(res => {
          //fetchData();
          //setColleges(colleges.filter((item) => item._id !== id));
          toast.success(res.data.message);
          navigate('/country');
          //setRerender((prev) => !prev);
        })
    }
    const deleteRecord = (id,status) => {
      console.log(id);
      var StatusMsg = (status==1)?'Inactive':'Active';
      confirmAlert({
        title: 'Confirm to submit',
        message: 'Are you sure to '+ StatusMsg +' this.',
        buttons: [
          {
            label: 'Yes',
            onClick: () => Delete(id,status)
          },
          {
            label: 'No',
            //onClick: () => alert('Click No')
          }
        ]
      });
    }

    const columns = [
      {
        name: 'SNO.',
        selector: (row, index) => index + 1,
        sortable: true,
      },
      {
        name: 'Country Name',
        selector: row => row.country_name,
        sortable: true,
      },
      {
        name: 'Short Name',
        selector: row => row.short_name,
        sortable: true,
      },
      {
        name: 'Action',
        selector: row=>
        <div style={{display:"flex"}}>
        <Link className="form-control" to={`/country/edit/${row._id}`}>Edit</Link>&nbsp;&nbsp;
        
        {/* <button  onClick={() => deleteRecord(row._id)}>Delete</button> */}
        {row.is_deleted==1?(<button className="form-control" style={{backgroundColor:"red"}} onClick={() => deleteRecord(row._id,0)}>Active</button>):(<button className="form-control"  onClick={() => deleteRecord(row._id,1)}>Deactive</button>)}
        </div>
      
    
    
    
      },
    ];
      return (
        <div className="row mt-3">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
                <div className="row">
                    <div className="text-end" ><h5 className="card-title"><Link to="/country/add">Add New</Link></h5></div>
                </div>
              
              <div className="table-responsive">
              <MyDataTable
                title="Country List" data={country} columns={columns}
              />
              
            </div>
            </div>
          </div>
        </div>
      </div>
      );
}

export default Country