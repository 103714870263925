import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { UndertakingFormSchema } from "../validation/UndertakingFormSchema";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import API from "../../API";


function SaveUndertaking() {

    const navigate = useNavigate();
    const params = useParams();
    const [undertakingDetails, setUndertakingDetails] = useState([]);
    useEffect(() => {
        fetchUndertakingData();
    }, []);

    const fetchUndertakingData = () => {
        setTimeout(() => {
        }, 3000);
        API.get(`undertaking/${params.id}`, {
            //   params: {
            //     id: params.id
            //   }
        }).then(({ data }) => {
            setUndertakingDetails(data.records);

        }).catch(error => {
            console.log(error.dat);
        })
    }
    const formInitialValue = {
        undertaking_name: undertakingDetails.undertaking_name ? undertakingDetails.undertaking_name : ''
    }
    const formik = useFormik({
        initialValues: formInitialValue,
        validationSchema: UndertakingFormSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            const undertakingData = {
                undertaking_name: values.undertaking_name
            };


            if (params.id) {
                API.put(`undertaking/${params.id}`,
                    undertakingData).then((res) => {
                        toast.success(res.data.message);
                        navigate('/undertaking');
                    }).catch((error) => {
                        console.log(error);
                        toast.error("Something went wrong");
                    });

            } else {
                API.post("undertaking",
                    undertakingData).then((res) => {
                        //alert("data inserted successfully");
                        toast.success(res.data.message);
                        navigate('/undertaking');
                    }).catch((error) => {
                        console.log(error);
                        toast.error("Something went wrong");
                    });
            }
        }
    });








    return (
        <div className="row mt-3">
            <div className="card mb-3">
                <div className="card-header">
                    <h5 className="card-title">{params.id ? 'Edit ' : 'Add '} Undertaking</h5>
                </div>
                <div className="card-body">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row mb-3">
                            <label for="inputEmail3" className="col-sm-3 col-form-label">Undertaking Name</label>
                            <div className="col-sm-9">
                                <input type="text" name="undertaking_name" id="undertaking_name" class="form-control" onBlur={formik.handleBlur} onChange={formik.handleChange} placeholder="Enter Undertaking Name" value={formik.values.undertaking_name} />
                                {formik.errors.undertaking_name && formik.touched.undertaking_name ? (<span style={{ color: "red" }}>{formik.errors.undertaking_name}</span>) : null}
                            </div>
                        </div>

                        <button type="submit" className="btn btn-success">Save</button> <Link to="/undertaking" className="btn btn-outline-light"> Back </Link>
                    </form>
                </div>
            </div>

        </div>
    );
}
export default SaveUndertaking;