import React, { useState } from 'react';
import DataTable from 'react-data-table-component';

const MyDataTable = ({ title, data, columns }) => {

    const [filterText, setFilterText] = useState('');

    const handleFilterChange = (e) => {
      const value = e.target.value || '';
      setFilterText(value?.toLowerCase());
    };
  
    const filteredData = data.filter(item =>
      columns.some(column =>
        //console.log(column)
        column.selector(item)?.toString()?.toLowerCase()?.includes(filterText)
      )
    );

   

    const customStyles = {
        header: {
          style: {
            backgroundColor: '#ffffff',
            color: '#000000',
            fontSize: '22px',
            fontWeight: 'bold',
            padding: '16px',
          },
        },
        rows: {
          style: {
            color:'#fff',

              '&:nth-of-type(odd)': {
                backgroundColor: '#002270', // color for odd rows
              },
              '&:nth-of-type(even)': {
                backgroundColor: '#131a4f', // color for even rows
              },
              outline: '1px solid #fff',
          },
        },
        headCells: {
          style: {
            paddingLeft: '8px',
            paddingRight: '8px',
            backgroundColor: '#ffffff',
            color: '#000000',
          },
        },
        cells: {
          style: {
            paddingLeft: '8px',
            paddingRight: '8px',
          },
        },
        pagination: {
          style: {
            backgroundColor: '#d1d5db',
            color: '#374151',
            borderTop: '1px solid #e5e7eb',
          },
          pageButtonsStyle: {
            borderRadius: '50%',
            height: '40px',
            width: '40px',
            padding: '8px',
            margin: '2px',
            cursor: 'pointer',
            transition: '0.3s',
            backgroundColor: '#ffffff',
            color: '#374151',
            '&:hover': {
              backgroundColor: '#9ca3af',
              color: '#ffffff',
            },
            '&:focus': {
              outline: 'none',
              backgroundColor: '#6b7280',
              color: '#ffffff',
            },
          },
        },
      };
    
      return (
        <div className="text-end">
          <h5 className="text-start card-title">{title}</h5>
          <input className="mb-2 w-25 rounded p-1"
            type="text"
            placeholder="Filter..."
            value={filterText}
            onChange={handleFilterChange}
        />
          <DataTable
            columns={columns}
            data={filteredData}
            customStyles={customStyles}
            pagination
          />
        </div>
      );
    };
    
export default MyDataTable;


