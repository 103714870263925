import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { StateFormSchema } from "../validation/StateFormSchema";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import API from "../../API";
function SaveState() {
    const navigate = useNavigate();
    const params = useParams();
    const [country, setCountry] = useState([]);
    const [stateDetails, setStateDetails] = useState([]);
    const [selectedOptionCountry, setSelectedOptionCountry] = useState('');
    useEffect(() => {
        fetchStateData();
        fetchCountries();
    }, []);

    const fetchStateData = () => {
        setTimeout(() => {
        }, 3000);
        API.get(`state/${params.id}`, {
            //   params: {
            //     id: params.id
            //   }
        }).then(({ data }) => {
            setStateDetails(data.records);
            console.log(data.records)
            if (data.records.country_id?._id) {
                setSelectedOptionCountry(data.records.country_id?._id);
            }
        }).catch(error => {
            console.log(error.dat);
        })
    }
    const fetchCountries = async () => {
        try {
            const response = await API.get('country/all');
            // console.log(response.data.records);
            setCountry(response.data.records);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };
    const handleChangeCountry = (event) => {
        setSelectedOptionCountry(event.target.value);
    };
    //console.log(stateDetails)
    const formInitialValue = {
        country_id: stateDetails.country_id?._id ? stateDetails.country_id?._id : '',
        state_name: stateDetails.state_name ? stateDetails.state_name : '',
        short_name: stateDetails.short_name ? stateDetails.short_name : ''
    }
    const formik = useFormik({
        initialValues: formInitialValue,
        validationSchema: StateFormSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            const stateData = {
                country_id: values.country_id,
                state_name: values.state_name,
                short_name: values.short_name
            };


            if (params.id) {
                API.put(`state/${params.id}`,
                    stateData).then((res) => {
                        toast.success(res.data.message);
                        navigate('/state');
                    }).catch((error) => {
                        console.log(error);
                        toast.error("Something went wrong");
                    });

            } else {
                API.post("state",
                    stateData).then((res) => {
                        //alert("data inserted successfully");
                        toast.success(res.data.message);
                        navigate('/state');
                    }).catch((error) => {
                        console.log(error);
                        toast.error("Something went wrong");
                    });
            }
        }
    });








    return (
        <div className="row mt-3">
            <div className="card mb-3">
                <div className="card-header">
                    <h5 className="card-title">{params.id ? 'Edit ' : 'Add '} State</h5>
                </div>
                <div className="card-body">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row mb-3">
                            <label for="inputEmail3" className="col-sm-3 col-form-label">Country Name</label>
                            <div className="col-sm-9">
                                <select name="country_id" id="country_id" className="form-control" onBlur={formik.handleBlur} onChange={(e) => {
                                    formik.handleChange(e);
                                    handleChangeCountry(e);
                                }} value={selectedOptionCountry}>
                                    <option value="">Select</option>
                                    {country.map((item, index) =>
                                        <option value={item._id}>{item.country_name}</option>
                                    )}
                                </select>
                                {formik.errors.country_id && formik.touched.country_id ? (<span style={{ color: "red" }}>{formik.errors.country_id}</span>) : null}
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label for="inputPassword3" className="col-sm-3 col-form-label">State Name </label>
                            <div className="col-sm-9">
                                <input type="text" name="state_name" id="state_name" className="form-control" onBlur={formik.handleBlur} onChange={formik.handleChange} placeholder="Enter State Name" value={formik.values.state_name} />
                                {formik.errors.state_name && formik.touched.state_name ? (<span style={{ color: "red" }}>{formik.errors.state_name}</span>) : null}
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label for="inputPassword3" className="col-sm-3 col-form-label">Short Name</label>
                            <div className="col-sm-9">
                                <input type="text" name="short_name" id="short_name" className="form-control" onBlur={formik.handleBlur} onChange={formik.handleChange} placeholder="Enter State Short Name" value={formik.values.short_name} />
                                {formik.errors.short_name && formik.touched.short_name ? (<span style={{ color: "red" }}>{formik.errors.short_name}</span>) : null}
                            </div>
                        </div>

                        <button type="submit" className="btn btn-success">Save</button> <Link to="/state" className="btn btn-outline-light"> Back </Link>
                    </form>
                </div>
            </div>

        </div>
    );
}
export default SaveState;