
let showLoaderFn;
let hideLoaderFn;

export const setLoaderFunctions = (showFn, hideFn) => {
  showLoaderFn = showFn;
  hideLoaderFn = hideFn;
};

export const showLoader = () => {
  if (showLoaderFn) showLoaderFn();
};

export const hideLoader = () => {
  if (hideLoaderFn) hideLoaderFn();
};