import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useState } from 'react';
import Loader from './components/Loader';
import API from './API';


import SignUp from './pages/SignUp';
import Login from './pages/Login';
import Layout from './pages/Layout';
import Dashboard from './pages/Dashboard';
import NoPage from './pages/NoPage';
import Colleges from './pages/college/Colleges';
import SaveColleges from './pages/college/SaveColleges';

import Notice from './pages/notice/Notice';
import SaveCollegeNotice from './pages/notice/SaveCollegeNotice';

import Users from './pages/user/Users';
import SaveUsers from './pages/user/SaveUsers';

import CourseType from './pages/coursetype/CourseType';
import SaveCourseType from './pages/coursetype/SaveCourseType';


import Course from './pages/courses/Course';
import SaveCourse from './pages/courses/SaveCourse';

import NoticeTypes from './pages/notice/NoticeTypes';
import SaveNoticeTypes from './pages/notice/SaveNoticeTypes';

import Country from './pages/location/Country';
import SaveCountry from './pages/location/SaveCountry';

import State from './pages/location/State';
import SaveState from './pages/location/SaveState';

import City from './pages/location/City';
import SaveCity from './pages/location/SaveCity';

import District from './pages/location/District';
import SaveDistrict from './pages/location/SaveDistrict';

import University from './pages/university/University';
import SaveUniversity from './pages/university/SaveUniversity';

import Undertaking from './pages/undertaking/Undertaking';
import SaveUndertaking from './pages/undertaking/SaveUndertaking';

import CollegeTypes from './pages/collegeTypes/CollegeTypes';
import SaveCollegeTypes from './pages/collegeTypes/SaveCollegeTypes';

import UniversityNotice from './pages/notice/UniversityNotice';
import SaveUniversityNotice from './pages/notice/SaveUniversityNotice';
import { setLoaderFunctions } from './services/loaderService';


function App() {

  const [loading, setLoading] = useState(false);
  setLoaderFunctions(() => setLoading(true), () => setLoading(false));
  return (
    
    <Router>
      {loading && <Loader />}
      <Routes>      
      <Route path="signup" element={<SignUp />} />
      <Route path="login" element={<Login />} />
        <Route path="/" element={<Layout />}>
          <Route  index element={<Dashboard />} />
          <Route path="/university" element={<University />} />
          <Route path="/university/add" element={<SaveUniversity />} />
          <Route path="/university/edit/:id" element={<SaveUniversity />} />
          <Route path="/colleges" element={<Colleges />} />
          <Route path="/users" element={<Users />} />
          <Route path="/users/add" element={<SaveUsers />} />
          <Route path="/users/edit/:id" element={<SaveUsers />} />
          <Route path="/course-type" element={<CourseType />} />
          <Route path="/course-type/add" element={<SaveCourseType />} />
          <Route path="/course-type/edit/:id" element={<SaveCourseType />} />
          <Route path="/course" element={<Course />} />
          <Route path="/course/add" element={<SaveCourse />} />
          <Route path="/course/edit/:id" element={<SaveCourse />} />
          <Route path="/notice-type" element={<NoticeTypes />} />
          <Route path="/notice-type/add" element={<SaveNoticeTypes />} />
          <Route path="/notice-type/edit/:id" element={<SaveNoticeTypes />} />
          <Route path="/colleges-notice" element={<Notice />} />
          <Route path="/colleges-notice/add" element={<SaveCollegeNotice />} />
          <Route path="/colleges-notice/edit/:id" element={<SaveCollegeNotice />} />

          <Route path="/university-notice" element={<UniversityNotice />} />
          <Route path="/university-notice/add" element={<SaveUniversityNotice />} />
          <Route path="/university-notice/edit/:id" element={<SaveUniversityNotice />} />
          <Route path="/college/add" element={<SaveColleges />} />
          <Route path="/college/edit/:id" element={<SaveColleges />} />
          <Route path="/country" element={<Country />} />
          <Route path="/country/add" element={<SaveCountry  />} />
          <Route path="/country/edit/:id" element={<SaveCountry  />} />
          <Route path="/state" element={<State />} />
          <Route path="/state/add" element={<SaveState />} />
          <Route path="/state/edit/:id" element={<SaveState />} />
          <Route path="/city" element={<City />} />
          <Route path="/city/add" element={<SaveCity />} />
          <Route path="/city/edit/:id" element={<SaveCity />} />
          <Route path="/district" element={<District />} />
          <Route path="/district/add" element={<SaveDistrict />} />
          <Route path="/district/edit/:id" element={<SaveDistrict />} />
          <Route path="/undertaking" element={<Undertaking />} />
          <Route path="/undertaking/add" element={<SaveUndertaking />} />
          <Route path="/undertaking/edit/:id" element={<SaveUndertaking />} />
          <Route path="/collegetypes" element={<CollegeTypes />} />
          <Route path="/collegetypes/add" element={<SaveCollegeTypes />} />
          <Route path="/collegetypes/edit/:id" element={<SaveCollegeTypes />} />
        </Route>
        <Route path="*" element={<NoPage />} />
      </Routes>
    </Router>
  );
}

export default App;
