import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { DistrictFormSchema } from "../validation/DistrictFormSchema";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import API from "../../API";

function SaveDistrict() {
    const params = useParams();
    const navigate = useNavigate();
    const [country, setCountry] = useState([]);
    const [state, setState] = useState([]);
    const [districtDetails, setDistrictDetails] = useState([]);
    const [selectedOptionCountry, setSelectedOptionCountry] = useState('');
    const [selectedOptionState, setSelectedOptionState] = useState('');
    useEffect(() => {
        fetchDistrictData();
        fetchCountries();
        // fetchState();
    }, []);

    const fetchDistrictData = () => {
        setTimeout(() => {
        }, 3000);
        API.get(`district/${params.id}`, {
            //   params: {
            //     id: params.id
            //   }
        }).then(({ data }) => {
            setDistrictDetails(data.records);
            console.log(data.records)
            if (data.records.country_id?._id) {
                setSelectedOptionCountry(data.records.country_id?._id);
                getStates(data.records.country_id?._id);
            }
            if (data.records.state_id?._id) {
                setSelectedOptionState(data.records.state_id?._id);
            }
        }).catch(error => {
            console.log(error.dat);
        })
    }

    const fetchCountries = async () => {
        try {
            const response = await API.get('country/all');
            console.log(response.data.records);
            setCountry(response.data.records);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const getStates = async (country_id) => {
        try {
            const response = await API.get(`state/getStates/${country_id}`);
            console.log(response.data.records);
            setState(response.data.records);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const handleChangeCountry = (event) => {
        setSelectedOptionCountry(event.target.value);
    };
    const handleChangeState = (event) => {
        setSelectedOptionState(event.target.value);
    };

    const formInitialValue = {
        country_id: districtDetails.country_id?._id,
        state_id: districtDetails.state_id?._id,
        district_name: districtDetails.district_name,
        short_name: districtDetails.short_name
    }
    const formik = useFormik({
        initialValues: formInitialValue,
        validationSchema: DistrictFormSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            const districtData = {
                country_id: values.country_id,
                state_id: values.state_id,
                district_name: values.district_name,
                short_name: values.short_name
            };

            if (params.id) {
                API.put(`district/${params.id}`,
                    districtData).then((res) => {
                        toast.success(res.data.message);
                        navigate('/district');
                    }).catch((error) => {
                        console.log(error);
                        toast.error("Something went wrong");
                    });

            } else {
                API.post("district",
                    districtData).then((res) => {
                        //alert("data inserted successfully");
                        toast.success(res.data.message);
                        navigate('/district');
                    }).catch((error) => {
                        console.log(error);
                        toast.error("Something went wrong");
                    });
            }
        }
    });


    return (
        <div className="row mt-3">
            <div className="card mb-3">
                <div className="card-header">
                    <h5 className="card-title">{params.id ? 'Edit ' : 'Add '} District</h5>
                </div>
                <div className="card-body">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row mb-3">
                            <label for="inputEmail3" className="col-sm-3 col-form-label">Country Name</label>
                            <div className="col-sm-9">
                                <select name="country_id" id="country_id" className="form-control" onBlur={formik.handleBlur} onChange={(e) => {
                                    formik.handleChange(e);
                                    handleChangeCountry(e);
                                    getStates(e.currentTarget.value);
                                }} value={selectedOptionCountry}>
                                    <option value="">Select</option>
                                    {country.map((item, index) =>
                                        <option value={item._id}>{item.country_name}</option>
                                    )}
                                </select>
                                {formik.errors.country_id && formik.touched.country_id ? (<span style={{ color: "red" }}>{formik.errors.country_id}</span>) : null}
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label for="inputPassword3" className="col-sm-3 col-form-label">State Name </label>
                            <div className="col-sm-9">
                                <select name="state_id" id="state_id" className="form-control" onBlur={formik.handleBlur} onChange={(e) => {
                                    formik.handleChange(e);
                                    handleChangeState(e);
                                }} value={selectedOptionState}>
                                    <option value="">Select</option>
                                    {Array.isArray(state) && state?.map((item, index) =>
                                        <option value={item._id}>{item.state_name}</option>
                                    )}
                                </select>
                                {formik.errors.state_id && formik.touched.state_id ? (<span style={{ color: "red" }}>{formik.errors.state_id}</span>) : null}
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label for="inputPassword3" className="col-sm-3 col-form-label">District Name</label>
                            <div className="col-sm-9">
                                <input type="text" name="district_name" id="district_name" className="form-control" onBlur={formik.handleBlur} onChange={formik.handleChange} placeholder="Enter District Name" value={formik.values.district_name} />
                                {formik.errors.district_name && formik.touched.district_name ? (<span style={{ color: "red" }}>{formik.errors.district_name}</span>) : null}
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label for="inputPassword3" className="col-sm-3 col-form-label">Short Name</label>
                            <div className="col-sm-9">
                                <input type="text" name="short_name" id="short_name" className="form-control" onBlur={formik.handleBlur} onChange={formik.handleChange} placeholder="Enter City Short Name" value={formik.values.short_name} />
                                {formik.errors.short_name && formik.touched.short_name ? (<span style={{ color: "red" }}>{formik.errors.short_name}</span>) : null}
                            </div>
                        </div>

                        <button type="submit" className="btn btn-success">Save</button> <Link to="/district" className="btn btn-outline-light"> Back </Link>
                    </form>
                </div>
            </div>

        </div>
    );
}
export default SaveDistrict;