import { useFormik } from "formik";
import React, { useState } from "react";
import Loader from "../components/Loader";
import { Link, useNavigate } from 'react-router-dom';
import { LoginFormSchema } from "./validation/LoginFormSchema";
import API from "../API";
//const API_URL = "http://localhost:4000/";
//console.log(API_URL);

function Login() {
    const formInitialValue = {
        email: '',
        password: ''
    }
    const formik = useFormik({
        initialValues: formInitialValue,
        validationSchema: LoginFormSchema,
        onSubmit: (values) => {
            //console.log(values.email);
            const loginData = {
                email: values.email,
                password: values.password
            };
            API.post("login", loginData).then((res) => {
                console.log(res.data);
                if (res.data.success) {
                    //alert(res.data.message);
                    localStorage.setItem("user", JSON.stringify(res.data.users));
                    localStorage.setItem("token", JSON.stringify(res.data.token));
                    return navigate("/");
                } else {
                    alert(res.data.message);
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    });

    const navigate = useNavigate();

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-4 col-lg-5 col-sm-6 col-12">
                    <form onSubmit={formik.handleSubmit} className="my-5">
                        <div className="card p-md-4 p-sm-3">
                            <div className="login-form">
                                <a href="index.html" className="mb-4 d-flex">
                                    <img src="assets/images/logo.svg" className="img-fluid login-logo" alt="Admin & Dashboards" />
                                </a>
                                <h3 className="mt-5 mb-4">Login</h3>
                                <div className="mb-3">
                                    <label className="form-label">Your Email</label>
                                    <input type="text" id="email" name="email" className="form-control input-shadow" placeholder="Enter Username" value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur} autoComplete="off" />
                                    {formik.errors.email && formik.touched.email ? (<span style={{ color: "red" }}>{formik.errors.email}</span>) : null}
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Your Password</label>
                                    <input type="password" id="password" name="password" className="form-control input-shadow" placeholder="Enter Password" value={formik.values.password} onChange={formik.handleChange} onBlur={formik.handleBlur} autoComplete="off" />                                    
                                    {formik.errors.password && formik.touched.password ? (<span style={{ color: "red" }}>{formik.errors.password}</span>) : null}
                                </div>

                                <div className="d-grid py-3 mt-3">
                                    <button type="submit" className="btn btn-lg btn-primary">
                                        LOGIN
                                    </button>
                                </div>

                                {/* <div className="text-center pt-4">
                                    <span>Not registered?</span>
                                    <Link  to="/signup" className="text-primary text-decoration-underline">
                                        SignUp</Link>
                                </div> */}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    );

}
export default Login;