import React,{useEffect,useState} from 'react'
import { Link,useNavigate,useLocation  } from "react-router-dom";
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert'; // Import
import API from "../../API";
import MyDataTable from "../../components/MyDataTable";

function NoticeTypes() {
    const location = useLocation();
    const navigate = useNavigate();
    const [noticeType, setNoticeType] = useState([]);

    useEffect(()=>{
        fetchNoticeTypes();
    }, [location]);
    const fetchNoticeTypes = async () => {
        try {
          const response = await API.get('notice-type');
          setNoticeType(response.data.records);
        } catch (error) {
          console.error('Error fetching users:', error);
        }
    };
   

    const Delete = async (id,status) => {
      //fetchData();
      await API({
        method: 'DELETE',
        url: `notice-type/${id}/${status}`,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        //fetchData();
        //setColleges(colleges.filter((item) => item._id !== id));
        toast.success(res.data.message);
        navigate('/notice-type');
        //setRerender((prev) => !prev);
      })
    }
    const deleteRecord = (id,status) => {
      var StatusMsg = (status==1)?'Inactive':'Active';
      confirmAlert({
        title: 'Confirm to submit',
        message: 'Are you sure to '+ StatusMsg +' this.',
        buttons: [
          {
            label: 'Yes',
            onClick: () => Delete(id,status)
          },
          {
            label: 'No',
            //onClick: () => alert('Click No')
          }
        ]
      });
    }
    const columns = [
      {
        name: 'SNO.',
        selector: (row, index) => index + 1,
        sortable: true,
      },
      {
        name: 'Notice Type Name',
        selector: row => row.notice_type_name,
        sortable: true,
      },
      {
        name: 'Action',
        selector: row=>
        <div style={{display:"flex"}}>
        <Link className="form-control"  to={`/notice-type/edit/${row._id}`}>Edit</Link>&nbsp;&nbsp;
        
        {/* <button  onClick={() => deleteRecord(row._id)}>Delete</button> */}
        {row.is_deleted==1?(<button className="form-control" style={{backgroundColor:"red"}} onClick={() => deleteRecord(row._id,0)}>Active</button>):(<button className="form-control" onClick={() => deleteRecord(row._id,1)}>Deactive</button>)}
        </div>
      },
    ];
  return (
    <div className="row mt-3">
    <div className="col-lg-12">
      <div className="card">
        <div className="card-body">
            <div className="row">
                <div className="text-end" ><h5 className="card-title"><Link to="/notice-type/add">Add New</Link></h5></div>
            </div>
          
            <div className="table-responsive">
            <MyDataTable
                title="Notice Types List" data={noticeType} columns={columns}
              />
        
        </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default NoticeTypes