import { useNavigate } from "react-router-dom";
import { useState } from "react";
import JQueryToggleComponent from './JQueryToggleComponent';
function Header() {
	const navigate = useNavigate();
	const [userImage, setUserImage] = useState('assets/images/user.png');
	const logout = async (e) => {
		localStorage.removeItem("user");
		localStorage.removeItem("token");
		navigate("/login");
	}

	return (
		<div className="app-header d-flex align-items-center">


			<div className="d-flex">
				<button className="btn btn-outline-light toggle-sidebar" id="toggle-sidebar">
					<i className="icon-menu"></i>
				</button>
				<button className="btn btn-outline-light pin-sidebar" id="pin-sidebar">
					<i className="icon-menu"></i>
				</button>
			</div>

			<div className="app-brand-sm d-md-none d-sm-block">
				<a href="index.html">
					<img src={`${process.env.PUBLIC_URL}/assets/images/logo-sm.svg`}  className="logo" alt="Bootstrap Gallery" />
				</a>
			</div>


			<div className="search-container d-lg-block d-none mx-3">
				<input type="text" className="form-control" placeholder="Search" />
				<i className="icon-search"></i>
			</div>



			<div className="header-actions">
				<div className="dropdown">
					<a className="dropdown-toggle d-flex p-3 position-relative" href="#!" role="button"
						data-bs-toggle="dropdown" aria-expanded="false">
						<img src={`${process.env.PUBLIC_URL}/assets/images/flags/1x1/gb.svg`} className="flag-img" alt="Bootstrap Dashboards" />
					</a>
					<div className="dropdown-menu dropdown-menu-end shadow-sm dropdown-menu-mini">
						<div className="country-container">
							<a href="index.html" className="py-2">
								<img src={`${process.env.PUBLIC_URL}/assets/images/flags/1x1/us.svg`} alt="Admin Panel" />
							</a>
							<a href="index.html" className="py-2">
								<img src={`${process.env.PUBLIC_URL}/assets/images/flags/1x1/in.svg`} alt="Admin Panels" />
							</a>
							<a href="index.html" className="py-2">
								<img src={`${process.env.PUBLIC_URL}/assets/images/flags/1x1/br.svg`} alt="Admin Dashboards" />
							</a>
							<a href="index.html" className="py-2">
								<img src={`${process.env.PUBLIC_URL}/assets/images/flags/1x1/tr.svg`} alt="Admin Themes" />
							</a>
							<a href="index.html" className="py-2">
								<img src={`${process.env.PUBLIC_URL}/assets/images/flags/1x1/id.svg`} alt="Google Admin" />
							</a>
						</div>
					</div>
				</div>
				<div className="dropdown d-sm-flex d-none">
					<a className="dropdown-toggle d-flex p-3 position-relative" href="#!" role="button"
						data-bs-toggle="dropdown" aria-expanded="false">
						<i className="icon-shopping-cart fs-4 lh-1"></i>
						<span className="count rounded-circle bg-danger">9</span>
					</a>
					<div className="dropdown-menu dropdown-menu-end dropdown-menu-md shadow-sm">
						<h5 className="fw-semibold px-3 py-2 m-0">Orders</h5>
						<a href="#" className="dropdown-item">
							<div className="d-flex align-items-start py-2">
								<div className="p-3 bg-danger border border-danger rounded-circle me-3">
									MS
								</div>
								<div className="m-0">
									<h6 className="mb-1 fw-semibold">Moory Sammy</h6>
									<p className="mb-1">Ordered an iPhone.</p>
									<p className="small m-0 opacity-50">3 Mins Ago</p>
								</div>
							</div>
						</a>
						<a href="#" className="dropdown-item">
							<div className="d-flex align-items-start py-2">
								<div className="p-3 bg-primary border border-primary rounded-circle me-3">
									KY
								</div>
								<div className="m-0">
									<h6 className="mb-1 fw-semibold">Kyle Yomaha</h6>
									<p className="mb-1">Purchased a MacBook.</p>
									<p className="small m-0 opacity-50">5 Mins Ago</p>
								</div>
							</div>
						</a>
						<a href="#" className="dropdown-item">
							<div className="d-flex align-items-start py-2">
								<div className="p-3 bg-success border border-success rounded-circle me-3">
									SB
								</div>
								<div className="m-0">
									<h6 className="mb-1 fw-semibold">Srinu Basava</h6>
									<p className="mb-1">Purchased a NotePad.</p>
									<p className="small m-0 opacity-50">7 Mins Ago</p>
								</div>
							</div>
						</a>
						<div className="d-grid p-3 border-top">
							<a href="#" className="btn btn-outline-primary">View all</a>
						</div>
					</div>
				</div>
				<div className="dropdown d-sm-flex d-none">
					<a className="dropdown-toggle d-flex p-3 position-relative" href="#!" role="button"
						data-bs-toggle="dropdown" aria-expanded="false">
						<i className="icon-twitch fs-4 lh-1"></i>
						<span className="count rounded-circle bg-danger">5</span>
					</a>
					<div className="dropdown-menu dropdown-menu-end dropdown-menu-md shadow-sm">
						<h5 className="fw-semibold px-3 py-2 m-0">Notifications</h5>
						<a href="#" className="dropdown-item">
							<div className="d-flex align-items-start py-2">
								<img src={`${process.env.PUBLIC_URL}/assets/images/user.png`} className="img-3x me-3 rounded-3" alt="Admin Themes" />
								<div className="m-0">
									<h6 className="mb-1 fw-semibold">Sophie Michiels</h6>
									<p className="mb-1">Membership has been ended.</p>
									<p className="small m-0 opacity-50">Today, 07:30pm</p>
								</div>
							</div>
						</a>
						<a href="#" className="dropdown-item">
							<div className="d-flex align-items-start py-2">
								<img src={`${process.env.PUBLIC_URL}/assets/images/user2.png`} className="img-3x me-3 rounded-3" alt="Admin Theme" />
								<div className="m-0">
									<h6 className="mb-1 fw-semibold">Sophie Michiels</h6>
									<p className="mb-1">Congratulate, James for new job.</p>
									<p className="small m-0 opacity-50">Today, 08:00pm</p>
								</div>
							</div>
						</a>
						<a href="#" className="dropdown-item">
							<div className="d-flex align-items-start py-2">
								<img src={`${process.env.PUBLIC_URL}/assets/images/user1.png`} className="img-3x me-3 rounded-3" alt="Admin Theme" />
								<div className="m-0">
									<h6 className="mb-1 fw-semibold">Sophie Michiels</h6>
									<p className="mb-1">Lewis added new schedule release.</p>
									<p className="small m-0 opacity-50">Today, 09:30pm</p>
								</div>
							</div>
						</a>
						<div className="d-grid p-3 border-top">
							<a href="#" className="btn btn-outline-primary">View all</a>
						</div>
					</div>
				</div>
				<div className="dropdown ms-2">
					<a className="dropdown-toggle d-flex align-items-center user-settings" href="#!" role="button"
						data-bs-toggle="dropdown" aria-expanded="false">
						<span>Fuzail Malik</span>
						<img src={`${process.env.PUBLIC_URL}/assets/images/user3.png`} className="img-3x m-2 me-0 rounded-3" alt="Admin Templates" />
					</a>
					<div className="dropdown-menu dropdown-menu-end dropdown-menu-sm shadow-sm gap-3">
						<a className="dropdown-item d-flex align-items-center py-2" href="profile.html"><i
							className="icon-gitlab fs-4 me-3"></i>User Profile</a>
						<a className="dropdown-item d-flex align-items-center py-2" href="account-settings.html"><i
							className="icon-settings fs-4 me-3"></i>Account Settings</a>
						<a className="dropdown-item d-flex align-items-center py-2" href="#" onClick={logout}><i
							className="icon-log-out fs-4 me-3"></i>Logout</a>
					</div>
				</div>
			</div>

		</div>
	);
}
export default Header;