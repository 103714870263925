import React,{useEffect,useState} from 'react'
import { Link,useNavigate,useLocation  } from "react-router-dom";
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert'; // Import
import API from '../../API';
import MyDataTable from "../../components/MyDataTable";

function Course() {
  const location = useLocation();
  const navigate = useNavigate();
  const [course, setCourse] = useState([]);

    useEffect(()=>{
        fetchCourse();
    }, [location]);
    const fetchCourse = async () => {
        try {
          const response = await API.get('course');
          setCourse(response.data.records);
        } catch (error) {
          console.error('Error fetching users:', error);
        }
    };

    const Delete = async (id,status) => {
      //fetchData();
      await API({
        method: 'DELETE',
        url: `course/${id}/${status}`,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        //fetchData();
        //setColleges(colleges.filter((item) => item._id !== id));
        toast.success(res.data.message);
        navigate('/course');
        //setRerender((prev) => !prev);
      })
    }
    const deleteRecord = (id,status) => {
      var StatusMsg = (status==1)?'Inactive':'Active';
      confirmAlert({
        title: 'Confirm to submit',
        message: 'Are you sure to '+ StatusMsg +' this.',
        buttons: [
          {
            label: 'Yes',
            onClick: () => Delete(id,status)
          },
          {
            label: 'No',
            //onClick: () => alert('Click No')
          }
        ]
      });
    }
    const columns = [
      {
        name: 'SNO.',
        selector: (row, index) => index + 1,
        sortable: true, 
      },
      {
        name: 'Course Name',
        selector: row => row.course_name,
        sortable: true,
      },
      {
        name: 'Short Name',
        selector: row => row.short_name,
        sortable: true,
      },
      {
        name: 'Action',
        selector: row=>
        <div style={{display:"flex"}}>
        <Link className="form-control" to={`/course/edit/${row._id}`}>Edit</Link>&nbsp;&nbsp;
        
        {/* <button  onClick={() => deleteRecord(row._id)}>Delete</button> */}
        {row.is_deleted==1?(<button className="form-control" style={{backgroundColor:"red"}} onClick={() => deleteRecord(row._id,0)}>Active</button>):(<button className="form-control" onClick={() => deleteRecord(row._id,1)}>Deactive</button>)}
        </div>
      },
    ];
  return (
    
    <div class="row mt-3">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="text-end" ><h5 class="card-title"><Link to="/course/add">Add New</Link></h5></div>
            </div>
          
            <div class="table-responsive">
            <MyDataTable
                title="Course List" data={course} columns={columns}
              />
            </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Course