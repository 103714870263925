import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { SignupFormSchema } from "../validation/SignupFormSchema";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import API from "../../API";


function SaveUsers() {
  const params = useParams();
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState([]);

  useEffect(() => {
    fetchUserData();
  }, []);
  const fetchUserData = () => {
    setTimeout(() => {
    }, 3000);
    API.get(`user/${params.id}`, {
      //   params: {
      //     id: params.id
      //   }
    }).then(({ data }) => {
      setUserDetails(data.records);
    }).catch(error => {
      console.log(error.dat);
    })
  }
  console.log(userDetails)
  const formInitialValue = {
    name: userDetails.name,
    email: userDetails.email,
    password: ''
  }

  const formik = useFormik({
    initialValues: formInitialValue,
    validationSchema: SignupFormSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      //console.log(values.email);
      const userData = {
        name: values.name,
        email: values.email,
        password: values.password
      };


      if (params.id) {
        API.put(`user/${params.id}`,
          userData).then((res) => {
            toast.success(res.data.message);
            navigate('/users');
          }).catch((error) => {
            console.log(error);
            toast.error("Something went wrong");
          });

      } else {
        API.post("signup",
          userData).then((res) => {
            //alert("data inserted successfully");
            toast.success(res.data.message);
            navigate('/users');
          }).catch((error) => {
            console.log(error);
            toast.error("Something went wrong");
          });
      }

    }
  });






  return (
    <div class="row mt-3">
      <div class="card mb-3">
        <div class="card-header">
          <h5 class="card-title">{params.id ? 'Edit ' : 'Add '} User</h5>
        </div>
        <div class="card-body">
          <form onSubmit={formik.handleSubmit}>
            <div class="row mb-3">
              <label for="inputEmail3" class="col-sm-3 col-form-label">Name</label>
              <div class="col-sm-9">
                <input type="text" id="name" name="name" className="form-control" placeholder="Enter Name" value={formik.values.name} onChange={formik.handleChange} onBlur={formik.handleBlur} autoComplete="off" />
                {formik.errors.name && formik.touched.name ? (<span style={{ color: "red" }}>{formik.errors.name}</span>) : null}
              </div>
            </div>
            <div class="row mb-3">
              <label for="inputPassword3" class="col-sm-3 col-form-label">Email ID</label>
              <div class="col-sm-9">
                <input type="text" id="email" name="email" className="form-control input-shadow" placeholder="Enter Username" value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur} autoComplete="off" />
                {formik.errors.email && formik.touched.email ? (<span style={{ color: "red" }}>{formik.errors.email}</span>) : null}
              </div>
            </div>

            <div class="row mb-3">
              <label for="inputPassword3" class="col-sm-3 col-form-label">Password</label>
              <div class="col-sm-9">
                <input type="password" id="password" name="password" className="form-control input-shadow" placeholder="Enter Password" value={formik.values.password} onChange={formik.handleChange} onBlur={formik.handleBlur} autoComplete="off" />
                {formik.errors.password && formik.touched.password ? (<span style={{ color: "red" }}>{formik.errors.password}</span>) : null}
              </div>
            </div>

            <button type="submit" class="btn btn-success">Save</button> <Link to="/users" className="btn btn-outline-light"> Back </Link>
          </form>
        </div>
      </div>

    </div>
  );
}
export default SaveUsers;