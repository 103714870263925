import { useFormik } from "formik";
import Loader from "../components/Loader";
import Logo from '../assets/images/logo-icon.png';
import { useNavigate } from 'react-router-dom';
import { SignupFormSchema } from "./validation/SignupFormSchema";
import API from "../API";

function SignUp() {
    const navigate = useNavigate();
    const formInitialValue = {
        name: '',
        email: '',
        password: ''
    }

    const formik = useFormik({
        initialValues: formInitialValue,
        validationSchema: SignupFormSchema,
        onSubmit: (values) => {
            //console.log(values.email);
            const signupData = {
                name: values.name,
                email: values.email,
                password: values.password
            };
            API.post("signup", signupData).then((res) => {
                console.log(res.data);
                if (res.data.success) {
                    alert(res.data.message);
                    // localStorage.setItem("user", JSON.stringify(res.data.users));
                    // localStorage.setItem("token", JSON.stringify(res.data.token));
                    navigate("/login");
                } else {
                    alert(res.data.message);
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    });

    return (
        // <!-- Start wrapper-->
        <div id="wrapper">

            <div class="card card-authentication1 mx-auto my-4">
                <div class="card-body">
                    <div class="card-content p-2">
                        <div class="text-center">
                            <img src="assets/images/logo-icon.png" alt="logo icon" />
                        </div>
                        <div class="card-title text-uppercase text-center py-3">Sign Up</div>
                        <form onSubmit={formik.handleSubmit}>
                            <div class="form-group">
                                <label for="exampleInputName" class="sr-only">Name</label>
                                <div class="position-relative has-icon-right">
                                    <input type="text" id="name" name="name" className="form-control input-shadow" placeholder="Enter Name" value={formik.values.name} onChange={formik.handleChange} onBlur={formik.handleBlur} autoComplete="off" />
                                    <div className="form-control-position">
                                        <i className="icon-user"></i>
                                    </div>
                                    {formik.errors.name && formik.touched.name ? (<span style={{ color: "red" }}>{formik.errors.name}</span>) : null}
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="exampleInputEmailId" class="sr-only">Email ID</label>
                                <div class="position-relative has-icon-right">
                                    <input type="text" id="email" name="email" className="form-control input-shadow" placeholder="Enter Username" value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur} autoComplete="off" />
                                    <div className="form-control-position">
                                        <i className="icon-user"></i>
                                    </div>
                                    {formik.errors.email && formik.touched.email ? (<span style={{ color: "red" }}>{formik.errors.email}</span>) : null}
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="exampleInputPassword" class="sr-only">Password</label>
                                <div class="position-relative has-icon-right">
                                    <input type="password" id="password" name="password" className="form-control input-shadow" placeholder="Enter Password" value={formik.values.password} onChange={formik.handleChange} onBlur={formik.handleBlur} autoComplete="off" />
                                    <div className="form-control-position">
                                        <i className="icon-lock"></i>
                                    </div>
                                    {formik.errors.password && formik.touched.password ? (<span style={{ color: "red" }}>{formik.errors.password}</span>) : null}
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="icheck-material-white">
                                    <input type="checkbox" id="user-checkbox" checked="" />
                                    <label for="user-checkbox">I Agree With Terms & Conditions</label>
                                </div>
                            </div>

                            <button type="submit" class="btn btn-light btn-block waves-effect waves-light">Sign Up</button>



                        </form>
                    </div>
                </div>
                <div class="card-footer text-center py-3">
                    <p class="text-warning mb-0">Already have an account? <a href="login"> Sign In here</a></p>
                </div>
            </div>

            {/* <!--Start Back To Top Button--> */}
            <a href="javaScript:void();" class="back-to-top"><i class="fa fa-angle-double-up"></i> </a>
            {/* <!--End Back To Top Button--> */}

            {/* <!--start color switcher--> */}
            <div class="right-sidebar">
                <div class="switcher-icon">
                    <i class="zmdi zmdi-settings zmdi-hc-spin"></i>
                </div>
                <div class="right-sidebar-content">

                    <p class="mb-0">Gaussion Texture</p>
                    <hr />

                    <ul class="switcher">
                        <li id="theme1"></li>
                        <li id="theme2"></li>
                        <li id="theme3"></li>
                        <li id="theme4"></li>
                        <li id="theme5"></li>
                        <li id="theme6"></li>
                    </ul>

                    <p class="mb-0">Gradient Background</p>
                    <hr />

                    <ul class="switcher">
                        <li id="theme7"></li>
                        <li id="theme8"></li>
                        <li id="theme9"></li>
                        <li id="theme10"></li>
                        <li id="theme11"></li>
                        <li id="theme12"></li>
                        <li id="theme13"></li>
                        <li id="theme14"></li>
                        <li id="theme15"></li>
                    </ul>

                </div>
            </div>
            {/* <!--end color switcher--> */}

        </div>

    );
}
export default SignUp;