import axios from 'axios';
import { showLoader,hideLoader } from './services/loaderService';

const API = axios.create({
  //baseURL: process.env.REACT_APP_API_URL,
  baseURL: 'https://college-api-7ck4.onrender.com/',
});

// Add a request interceptor
API.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    showLoader();
    return config;
  },
  error => {
    hideLoader();
    return Promise.reject(error);
  }
);

// Add a response interceptor
API.interceptors.response.use(
  response => {
    // Do something with the response data
    hideLoader();
    return response;
  },
  error => {
    hideLoader();
    // Do something with the response error
    if (error.response && error.response.status === 401) {
      // Handle unauthorized error
      // For example, redirect to login
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export default API;