import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { CourseTypeFormSchema } from "../validation/CourseTypeFormSchema";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import API from "../../API";


function SaveCourseType() {
    const params = useParams();
    const navigate = useNavigate();
    const [courseTypeDetails, setCourseTypeDetails] = useState([]);
    useEffect(() => {
        fetchCourseTypeData();
    }, []);

    const fetchCourseTypeData = () => {
        setTimeout(() => {
        }, 3000);
        API.get(`course-type/${params.id}`, {
            //   params: {
            //     id: params.id
            //   }
        }).then(({ data }) => {
            setCourseTypeDetails(data.records);
        }).catch(error => {
            console.log(error.dat);
        })
    }

    const formInitialValue = {
        course_type_name: courseTypeDetails.course_type_name ? courseTypeDetails.course_type_name : '',
        short_name: courseTypeDetails.short_name ? courseTypeDetails.short_name : '',
    }
    const formik = useFormik({
        initialValues: formInitialValue,
        validationSchema: CourseTypeFormSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            const courseTypeData = {
                course_type_name: values.course_type_name,
                short_name: values.short_name
            };

            if (params.id) {
                API.put(`course-type/${params.id}`,
                    courseTypeData).then((res) => {
                        toast.success(res.data.message);
                        navigate('/course-type');
                    }).catch((error) => {
                        console.log(error);
                        toast.error("Something went wrong");
                    });

            } else {
                API.post("course-type",
                    courseTypeData).then((res) => {
                        //alert("data inserted successfully");
                        toast.success(res.data.message);
                        navigate('/course-type');
                    }).catch((error) => {
                        console.log(error);
                        toast.error("Something went wrong");
                    });
            }

        }
    });








    return (
        <div className="row mt-3">
            <div className="card mb-3">
                <div className="card-header">
                    <h5 className="card-title">{params.id ? 'Edit ' : 'Add '} Course Type</h5>
                </div>
                <div className="card-body">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row mb-3">
                            <label for="inputEmail3" className="col-sm-3 col-form-label">Course Type Name <span className="text-danger">*</span></label>
                            <div className="col-sm-9">
                                <input type="text" name="course_type_name" id="course_type_name" className="form-control" onBlur={formik.handleBlur} onChange={formik.handleChange} placeholder="Course Type Name" value={formik.values.course_type_name} />
                                {formik.errors.course_type_name && formik.touched.course_type_name ? (<span style={{ color: "red" }}>{formik.errors.course_type_name}</span>) : null}
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label for="inputEmail3" className="col-sm-3 col-form-label">Short Name<span className="text-danger">*</span></label>
                            <div className="col-sm-9">
                                <input type="text" name="short_name" id="short_name" className="form-control" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.short_name} placeholder="Short Name" />
                                {formik.errors.short_name && formik.touched.short_name ? (<span style={{ color: "red" }}>{formik.errors.short_name}</span>) : null}
                            </div>
                        </div>

                        <button type="submit" className="btn btn-success">Save</button> <Link to="/course-type" className="btn btn-outline-light"> Back </Link>
                    </form>
                </div>
            </div>

        </div>
    );
}
export default SaveCourseType;