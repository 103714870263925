import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { NoticeTypeFormSchema } from "../validation/NoticeTypeFormSchema";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import API from "../../API";

const API_URL = process.env.REACT_APP_API_URL;
function SaveNoticeTypes() {
    const params = useParams();
    const navigate = useNavigate();
    const [noticeTypeDetails, setNoticeTypeDetails] = useState([]);
    useEffect(() => {
        fetchNoticeTypeData();
    }, []);

    const fetchNoticeTypeData = () => {
        setTimeout(() => {
        }, 3000);
        API.get(`notice-type/${params.id}`, {
            //   params: {
            //     id: params.id
            //   }
        }).then(({ data }) => {
            setNoticeTypeDetails(data.records);
        }).catch(error => {
            console.log(error.dat);
        })
    }

    const formInitialValue = {
        notice_type_name: noticeTypeDetails.notice_type_name ? noticeTypeDetails.notice_type_name : ''
    }
    const formik = useFormik({
        initialValues: formInitialValue,
        validationSchema: NoticeTypeFormSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            const noticeTypeData = {
                notice_type_name: values.notice_type_name
            };


            if (params.id) {
                API.put(`notice-type/${params.id}`,
                    noticeTypeData).then((res) => {
                        toast.success(res.data.message);
                        navigate('/notice-type');
                    }).catch((error) => {
                        console.log(error);
                        toast.error("Something went wrong");
                    });

            } else {
                API.post("notice-type",
                    noticeTypeData).then((res) => {
                        //alert("data inserted successfully");
                        toast.success(res.data.message);
                        navigate('/notice-type');
                    }).catch((error) => {
                        console.log(error);
                        toast.error("Something went wrong");
                    });
            }

        }
    });








    return (
        <div className="row mt-3">
            <div className="card mb-3">
                <div className="card-header">
                    <h5 className="card-title">{params.id ? 'Edit ' : 'Add '} Notice Type</h5>
                </div>
                <div className="card-body">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row mb-3">
                            <label for="inputEmail3" className="col-sm-3 col-form-label">Notice Type Name <span className="text-danger">*</span></label>
                            <div className="col-sm-9">
                                <input type="text" name="notice_type_name" id="notice_type_name" className="form-control" onBlur={formik.handleBlur} onChange={formik.handleChange} placeholder="Notice Type Name" value={formik.values.notice_type_name} />
                                {formik.errors.notice_type_name && formik.touched.notice_type_name ? (<span style={{ color: "red" }}>{formik.errors.notice_type_name}</span>) : null}
                            </div>
                        </div>

                        <button type="submit" className="btn btn-success">Save</button> <Link to="/notice-type" className="btn btn-outline-light"> Back </Link>
                    </form>
                </div>
            </div>

        </div>
    );
}
export default SaveNoticeTypes;