import React,{useState,useEffect} from "react";
import { confirmAlert } from 'react-confirm-alert'; 
import { Link,useNavigate,useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import API from "../../API";
import MyDataTable from "../../components/MyDataTable";


const Users = () => {
  const location = useLocation();
  const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    useEffect(()=>{
        fetchNotice();
    }, [location]);
    const fetchNotice = async () => {
        try {
          const response = await API.get('user');
          //console.log(response.data.records);
          setUsers(response.data.records);        
        } catch (error) {
          console.error('Error fetching users:', error);
        }
    };




    const Delete = async (id,status) => {
        //fetchData();
        await API({
            method: 'DELETE',
            url: `user/${id}/${status}`,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(res => {
          //fetchData();
          //setColleges(colleges.filter((item) => item._id !== id));
          toast.success(res.data.message);
          navigate('/users');
          //setRerender((prev) => !prev);
        })
    }
    const deleteRecord = (id,status) => {
      var StatusMsg = (status==1)?'Inactive':'Active';
      confirmAlert({
        title: 'Confirm to submit',
        message: 'Are you sure to '+ StatusMsg +' this.',
        buttons: [
          {
            label: 'Yes',
            onClick: () => Delete(id,status)
          },
          {
            label: 'No',
            //onClick: () => alert('Click No')
          }
        ]
      });
    }

    const columns = [
      {
        name: 'Srl.',
        selector: (row, index) => index + 1,
        sortable: true,
      },
      {
        name: 'Name',
        selector: row => row.name,
        sortable: true,
        id: "name",
      },
      {
        name: 'Email',
        selector: row => row.email,
        sortable: true,
      },
      {
        name: 'Action',
        selector: row=>
        <div style={{display:"flex"}}>
        <Link className="form-control" to={`/users/edit/${row._id}`}>Edit</Link> &nbsp;
        
        {/* <button  onClick={() => deleteRecord(row._id)}>Delete</button> */}
        {row.is_deleted==1?(<button style={{backgroundColor:"red"}} className="form-control" onClick={() => deleteRecord(row._id,0)}>Active</button>):(<button className="form-control" onClick={() => deleteRecord(row._id,1)}>Deactive</button>)}
        </div>
      
    
    
    
      },
    ];

    
      return (
        <div className="row mt-3">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
                <div className="row">
                    {/* <div className="col-md-6 text-left" ><h5 className="card-title">User List</h5></div> */}
                    <div className="text-end" ><h5 className="card-title"><Link to="/users/add">Add New</Link></h5></div>
                </div>
              
              <div className="table-responsive">
             
             {/* <div className="card mb-4">
										<div className="card-header">
											<div className="card-title">Simple Data Table</div>
										</div>
										<div className="card-body">
											<div className="table-responsive">
												<table id="basicExample" className="table table-striped">
													<thead>
														<tr>
															<th>Name</th>
															<th>Position</th>
															<th>Office</th>
															<th>Age</th>
															<th>Start date</th>
															<th>Salary</th>
														</tr>
													</thead>
													<tbody>
                          {users.map((item,index)=>
														<tr>
															<td>{index+1}</td>
															<td>{item.name}</td>
															<td>{item.email}</td>
															<td>61</td>
															<td>2021/04/25</td>
															<td>$320,800</td>
														</tr>
													 )}
													</tbody>
												</table>
											</div>
										</div>
									</div> */}
        
              <MyDataTable
                title="User List" data={users} columns={columns}
              />
            </div>
            </div>
          </div>
        </div>
      </div>
      );
}

export default Users