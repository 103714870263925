import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { UniversityFormSchema } from "../validation/UniversityFormSchema";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import API from "../../API";

function SaveUniversity() {
    const params = useParams();
    const navigate = useNavigate();
    const [country, setCountry] = useState([]);
    const [state, setState] = useState([]);
    const [city, setCity] = useState([]);
    const [district, setDistrict] = useState([]);
    const [undertaking, setUndertaking] = useState([]);
    const [universityDetails, setUniversityDetails] = useState([]);
    const [selectedOptionCountry, setSelectedOptionCountry] = useState('');
    const [selectedOptionState, setSelectedOptionState] = useState('');
    const [selectedOptionCity, setSelectedOptionCity] = useState('');
    const [selectedOptionDistrict, setSelectedOptionDistrict] = useState('');
    const [selectedOptionUndertaking, setSelectedOptionUndertaking] = useState('');
    useEffect(() => {
        fetchUniversityData();
        fetchCountries();
        fetchUndertaking();
    }, []);
    const fetchUniversityData = () => {
        setTimeout(() => {
        }, 3000);
        API.get(`university/${params.id}`, {
            //   params: {
            //     id: params.id
            //   }
        }).then(({ data }) => {
            setUniversityDetails(data.records);
            console.log(data.records);
            if (data.records.undertaking?._id) {
                setSelectedOptionUndertaking(data.records.undertaking?._id);
            }
            if (data.records.country?._id) {
                getStates(data.records.country?._id);
                setSelectedOptionCountry(data.records.country?._id);
            }
            if (data.records.state?._id) {
                getCities(data.records.state?._id);
                getDistrict(data.records.state?._id);
                setSelectedOptionState(data.records.state?._id);
            }
            if (data.records.city?._id) {
                setSelectedOptionCity(data.records.city?._id);
            }
            if (data.records.district?._id) {
                setSelectedOptionDistrict(data.records.district?._id);
            }
        }).catch(error => {
            console.log(error.dat);
        })
    }

    const fetchCountries = async () => {
        try {
            const response = await API.get('country/all');

            setCountry(response.data.records);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };
    const getStates = async (country_id) => {
        try {
            const response = await API.get(`state/getStates/${country_id}`);

            setState(response.data.records);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const getCities = async (state_id) => {
        try {
            const response = await API.get(`city/getCities/${state_id}`);

            setCity(response.data.records);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };
    const getDistrict = async (state_id) => {
        try {
            const response = await API.get(`district/getDistrict/${state_id}`);

            setDistrict(response.data.records);
        } catch (error) {
            console.error('Error fetching :', error);
        }
    };



    const fetchUndertaking = async () => {
        try {
            const response = await API.get('undertaking');

            setUndertaking(response.data.records);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };
    const handleChangeUndertaking = (event) => {
        setSelectedOptionUndertaking(event.target.value);
    };
    const handleChangeCountry = (event) => {
        setSelectedOptionCountry(event.target.value);
    };
    const handleChangeState = (event) => {
        setSelectedOptionState(event.target.value);
    };
    const handleChangeCity = (event) => {
        setSelectedOptionCity(event.target.value);
    };
    const handleChangeDistrict = (event) => {
        setSelectedOptionDistrict(event.target.value);
    };
    const formInitialValue = {
        banner_img: null,
        banner_img_previous: universityDetails.banner_img ? universityDetails.banner_img : '',
        logo_img: null,
        logo_img_previous: universityDetails.logo_img ? universityDetails.logo_img : '',
        university_name: universityDetails.university_name ? universityDetails.university_name : '',
        short_name: universityDetails.short_name ? universityDetails.short_name : '',
        university_code: universityDetails.university_code ? universityDetails.university_code : '',
        other_name: universityDetails.other_name ? universityDetails.other_name : '',
        eshtablish: universityDetails.eshtablish ? universityDetails.eshtablish : '',
        discription: universityDetails.discription ? universityDetails.discription : '',
        undertaking: universityDetails.undertaking?._id ? universityDetails.undertaking?._id : '',
        accreditation: universityDetails.accreditation ? universityDetails.accreditation : '',
        chancellor: universityDetails.chancellor ? universityDetails.chancellor : '',

        address: universityDetails.address ? universityDetails.address : '',
        landmark: universityDetails.landmark ? universityDetails.landmark : '',
        city: universityDetails.city?._id ? universityDetails.city?._id : '',
        district: universityDetails.district?._id ? universityDetails.district?._id : '',
        state: universityDetails.state?._id ? universityDetails.state?._id : '',
        country: universityDetails.country?._id ? universityDetails.country?._id : '',

        email: universityDetails.email ? universityDetails.email : '',
        email2: universityDetails.email2 ? universityDetails.email2 : '',
        phone: universityDetails.phone ? universityDetails.phone : '',
        website_url: universityDetails.website_url ? universityDetails.website_url : '',
        website_display: universityDetails.website_display ? universityDetails.website_display : ''
    }
    const formik = useFormik({
        initialValues: formInitialValue,
        validationSchema: UniversityFormSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            const universityData = new FormData();
            for (let value in values) {
                universityData.append(value, values[value]);
            }


            if (params.id) {
                API.put(`university/${params.id}`,
                    universityData).then((res) => {
                        toast.success(res.data.message);
                        navigate('/university');
                    }).catch((error) => {
                        console.log(error);
                        toast.error("Something went wrong");
                    });

            } else {
                API.post("university",
                    universityData).then((res) => {
                        //alert("data inserted successfully");
                        toast.success(res.data.message);
                        navigate('/university');
                    }).catch((error) => {
                        console.log(error);
                        toast.error("Something went wrong");
                    });
            }

            // const universityData = {
            //     university_name: values.university_name,
            //     short_name: values.short_name,
            //     university_code: values.university_code,
            //     other_name: values.other_name,
            //     eshtablish: values.eshtablish,
            //     discription: values.discription,
            //     undertaking: values.undertaking,
            //     accreditation: values.accreditation,
            //     chancellor: values.chancellor,

            //     address: values.address,
            //     landmark: values.landmark,
            //     city: values.city,
            //     district: values.district,
            //     state: values.state,
            //     country: values.country,

            //     email: values.email,
            //     email2: values.email2,
            //     phone: values.phone,
            //     website_url: values.website_url,
            //     website_display: values.website_display
            // };
            // axios.post(API_URL + "university",
            //     universityData).then((res) => {
            //         alert("data inserted successfully");
            //     }).catch((error) => {
            //         console.log(error);
            //     });
        }
    });








    return (
        <div className="row mt-3">
            <div className="card mb-3">
                <div className="card-header">
                    <h5 className="card-title">{params.id ? 'Edit ' : 'Add '} University</h5>
                </div>
                <div className="card-body">
                    <form onSubmit={formik.handleSubmit} encType='multipart/form-data'>
                        <h5 className="border-bottom text-danger" style={{ fontFamily: 'Rubik' }}><i className="fa fa-file-text-o"></i> Basic Info</h5>
                        <div className="row mb-3">
                            <div className="col-sm-6">
                                <label for="inputEmail3" className="col-form-label">University Name <span className="text-danger">*</span></label>
                                <div>
                                    <input type="text" className="form-control" name="university_name" id="university_name" placeholder="Eg: Culcutta University" value={formik.values.university_name} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                    {formik.errors.university_name && formik.touched.university_name ? (<span style={{ color: "red" }}>{formik.errors.university_name}</span>) : null}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <label for="inputEmail3" className="col-form-label">University Short Name </label>
                                <div >
                                    <input type="text" className="form-control" name="short_name" id="short_name" placeholder="Eg: Lady Brabourne College" onBlur={formik.handleBlur} value={formik.values.short_name} onChange={formik.handleChange} />
                                    {formik.errors.short_name && formik.touched.short_name ? (<span style={{ color: "red" }}>{formik.errors.short_name}</span>) : null}
                                </div>

                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-sm-6">
                                <label for="inputEmail3" className="col-form-label">University Code </label>
                                <div>
                                    <input type="text" className="form-control" name="university_code" id="university_code" placeholder="Eg: 012" onBlur={formik.handleBlur} value={formik.values.university_code} onChange={formik.handleChange} />
                                    {formik.errors.university_code && formik.touched.university_code ? (<span style={{ color: "red" }}>{formik.errors.university_code}</span>) : null}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <label for="inputEmail3" className="col-form-label">Other / Former Name</label>
                                <div >
                                    <input type="text" className="form-control" name="other_name" id="other_name" placeholder="Eg: South Suburban College" onBlur={formik.handleBlur} value={formik.values.other_name} onChange={formik.handleChange} />
                                    {formik.errors.other_name && formik.touched.other_name ? (<span style={{ color: "red" }}>{formik.errors.other_name}</span>) : null}
                                </div>

                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-sm-6">
                                <label for="inputEmail3" className="col-form-label">Estd.</label>
                                <div>
                                    <input type="text" className="form-control" name="eshtablish" id="eshtablish" placeholder="Eg: 1916" onBlur={formik.handleBlur} value={formik.values.eshtablish} onChange={formik.handleChange} />
                                    {formik.errors.eshtablish && formik.touched.eshtablish ? (<span style={{ color: "red" }}>{formik.errors.eshtablish}</span>) : null}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <label for="inputEmail3" className="col-form-label">University Discription</label>
                                <div >
                                    <input type="text" className="form-control" name="discription" id="discription" placeholder="Enter Description" onBlur={formik.handleBlur} value={formik.values.discription} onChange={formik.handleChange} />
                                    {formik.errors.discription && formik.touched.discription ? (<span style={{ color: "red" }}>{formik.errors.discription}</span>) : null}
                                </div>

                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-sm-6">
                                <label for="inputEmail3" className="col-form-label">Undertaking</label>
                                <div>
                                    <select name="undertaking" id="undertaking" className="form-select" onBlur={formik.handleBlur} onChange={(e) => {
                                        handleChangeUndertaking(e);
                                        formik.handleChange(e);
                                    }} value={selectedOptionUndertaking}>
                                        <option value="">Select</option>
                                        {Array.isArray(undertaking) && undertaking?.map((item, index) =>
                                            <option key={index} value={item._id}>{item.undertaking_name}</option>
                                        )}
                                    </select>
                                    {formik.errors.undertaking && formik.touched.undertaking ? (<span style={{ color: "red" }}>{formik.errors.undertaking}</span>) : null}
                                </div>
                            </div>
                            {/* <div className="col-sm-6">
                                <label for="inputEmail3" className="col-form-label">Affiliation<span className="text-danger">*</span></label>
                                <div >
                                    <select name="affiliation" id="affiliation" className="form-select" onBlur={formik.handleBlur} onChange={(e) => {
                                        handleChangeUniversity(e);
                                        formik.handleChange(e);
                                    }} value={selectedOptionUniversity}>
                                        <option value="">Select</option>
                                        {Array.isArray(university) && university?.map((item, index) =>
                                            <option key={index} value={item._id}>{item.university_name}</option>
                                        )}
                                    </select>
                                    {formik.errors.affiliation && formik.touched.affiliation ? (<span style={{ color: "red" }}>{formik.errors.affiliation}</span>) : null}
                                </div>

                            </div> */}
                        </div>
                        <div className="row mb-3">
                            <div className="col-sm-6">
                                <label for="inputEmail3" className="col-form-label">Accreditation</label>
                                <div>
                                    <input type="text" className="form-control" name="accreditation" id="accreditation" placeholder="Eg: NAAC, AICTE" onBlur={formik.handleBlur} value={formik.values.accreditation} onChange={formik.handleChange} />
                                    {formik.errors.accreditation && formik.touched.accreditation ? (<span style={{ color: "red" }}>{formik.errors.accreditation}</span>) : null}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <label for="inputEmail3" className="col-form-label">Chancellor / TIC</label>
                                <div >
                                    <input type="text" className="form-control" name="chancellor" id="chancellor" placeholder="Eg: Dr Dipak Kumar" onBlur={formik.handleBlur} value={formik.values.chancellor} onChange={formik.handleChange} />
                                    {formik.errors.chancellor && formik.touched.chancellor ? (<span style={{ color: "red" }}>{formik.errors.chancellor}</span>) : null}
                                </div>

                            </div>
                        </div>
                        <h5 className="border-bottom text-danger" style={{ fontFamily: 'Rubik' }}><i className="fa fa-globe"></i> Contact Info</h5>
                        <div className="row mb-3">
                            <div className="col-sm-6">
                                <label for="inputEmail3" className="col-form-label">Address<span className="text-danger">*</span></label>
                                <div>
                                    <input type="text" className="form-control" name="address" id="address" placeholder="Address" onBlur={formik.handleBlur} value={formik.values.address} onChange={formik.handleChange} />
                                    {formik.errors.address && formik.touched.address ? (<span style={{ color: "red" }}>{formik.errors.address}</span>) : null}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <label for="inputEmail3" className="col-form-label">Landmark</label>
                                <div >
                                    <input type="text" className="form-control" name="landmark" id="landmark" placeholder="Eg: Near SBI Bank" onBlur={formik.handleBlur} value={formik.values.landmark} onChange={formik.handleChange} />
                                    {formik.errors.landmark && formik.touched.landmark ? (<span style={{ color: "red" }}>{formik.errors.landmark}</span>) : null}
                                </div>

                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-sm-6">
                                <label for="inputEmail3" className="col-form-label">Country<span className="text-danger">*</span></label>
                                <div>
                                    <select name="country" id="country" className="form-select" onBlur={formik.handleBlur} onChange={(e) => {
                                        formik.handleChange(e);
                                        handleChangeCountry(e);
                                        getStates(e.currentTarget.value);
                                    }} value={selectedOptionCountry}>
                                        <option value="">Select</option>
                                        {country.map((item, index) =>
                                            <option key={index} value={item._id}>{item.country_name}</option>
                                        )}
                                    </select>
                                    {formik.errors.country && formik.touched.country ? (<span style={{ color: "red" }}>{formik.errors.country}</span>) : null}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <label for="inputEmail3" className="col-form-label">State<span className="text-danger">*</span></label>
                                <div >
                                    <select name="state" id="state" className="form-select" onBlur={formik.handleBlur} onChange={(e) => {
                                        formik.handleChange(e);
                                        getCities(e.currentTarget.value);
                                        getDistrict(e.currentTarget.value);
                                        handleChangeState(e);
                                    }} value={selectedOptionState}>
                                        <option value="">Select</option>
                                        {Array.isArray(state) && state?.map((item, index) =>
                                            <option key={index} value={item._id}>{item.state_name}</option>
                                        )}
                                    </select>
                                    {formik.errors.state && formik.touched.state ? (<span style={{ color: "red" }}>{formik.errors.state}</span>) : null}
                                </div>

                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-sm-6">
                                <label for="inputEmail3" className="col-form-label">City / Town<span className="text-danger">*</span></label>
                                <div>
                                    <select name="city" id="city" className="form-select" onBlur={formik.handleBlur} onChange={(e) => {
                                        formik.handleChange(e);
                                        handleChangeCity(e);
                                    }} value={selectedOptionCity}>
                                        <option value="">Select</option>
                                        {Array.isArray(city) && city?.map((item, index) =>
                                            <option value={item._id}>{item.city_name}</option>
                                        )}
                                    </select>
                                    {formik.errors.city && formik.touched.city ? (<span style={{ color: "red" }}>{formik.errors.city}</span>) : null}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <label for="inputEmail3" className="col-form-label">District<span className="text-danger">*</span></label>
                                <div >
                                    <select name="district" id="district" className="form-select" onBlur={formik.handleBlur} onChange={(e) => {
                                        formik.handleChange(e);
                                        handleChangeDistrict(e);
                                    }} value={selectedOptionDistrict}>
                                        <option value="">Select</option>
                                        {Array.isArray(district) && district?.map((item, index) =>
                                            <option key={index} value={item._id}>{item.district_name}</option>
                                        )}
                                    </select>
                                    {formik.errors.district && formik.touched.district ? (<span style={{ color: "red" }}>{formik.errors.district}</span>) : null}
                                </div>

                            </div>
                        </div>
                        <h5 className="border-bottom text-danger" style={{ fontFamily: 'Rubik' }}><i className="fa fa-globe"></i> Social Info</h5>

                        <div className="row mb-3">
                            <div className="col-sm-6">
                                <label for="inputEmail3" className="col-form-label">E-mail Id 1<span className="text-danger">*</span></label>
                                <div>
                                    <input type="text" className="form-control" name="email" id="email" placeholder="Enter College Email Address" onBlur={formik.handleBlur} value={formik.values.email} onChange={formik.handleChange} />
                                    {formik.errors.email && formik.touched.email ? (<span style={{ color: "red" }}>{formik.errors.email}</span>) : null}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <label for="inputEmail3" className="col-form-label">E-mail Id 2</label>
                                <div >
                                    <input type="text" className="form-control" name="email2" id="email2" placeholder="Enter Email Id" onBlur={formik.handleBlur} value={formik.values.email2} onChange={formik.handleChange} />
                                    {formik.errors.email2 && formik.touched.email2 ? (<span style={{ color: "red" }}>{formik.errors.email2}</span>) : null}
                                </div>

                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-sm-6">
                                <label for="inputEmail3" className="col-form-label">Phone</label>
                                <div>
                                    <input type="text" className="form-control" name="phone" id="phone" placeholder="Enter College Phone Number" onBlur={formik.handleBlur} value={formik.values.phone} onChange={formik.handleChange} />
                                    {formik.errors.phone && formik.touched.phone ? (<span style={{ color: "red" }}>{formik.errors.phone}</span>) : null}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <label for="inputEmail3" className="col-form-label">Website URL</label>
                                <div >
                                    <input type="text" className="form-control" name="website_url" id="website_url" placeholder="http(s)://www.abc.com" onBlur={formik.handleBlur} value={formik.values.website_url} onChange={formik.handleChange} />
                                    {formik.errors.website_url && formik.touched.website_url ? (<span style={{ color: "red" }}>{formik.errors.website_url}</span>) : null}
                                </div>

                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-sm-6">
                                <label for="inputEmail3" className="col-form-label">Website Display</label>
                                <div>
                                    <input type="text" className="form-control" name="website_display" id="website_display" placeholder="www.abc.com" onBlur={formik.handleBlur} value={formik.values.website_display} onChange={formik.handleChange} />
                                    {formik.errors.website_display && formik.touched.website_display ? (<span style={{ color: "red" }}>{formik.errors.website_display}</span>) : null}
                                </div>
                            </div>
                        </div>

                        <h5 className="border-bottom text-danger" style={{ fontFamily: 'Rubik' }}><i className="fa fa-book"></i> Images </h5>
                        <div className="row mb-3">
                            <div className="col-sm-6">
                                <label for="inputEmail3" className="col-form-label">Banner Image</label>
                                <div>
                                    <input type="file" className="form-control" name="banner_img" id="banner_img" accept='image/*'
                                        onChange={(e) => formik.setFieldValue('banner_img', e.currentTarget.files[0])} onBlur={formik.handleBlur} />
                                    {formik.errors.banner_img && formik.touched.banner_img ? (<span style={{ color: "red" }}>{formik.errors.banner_img}</span>) : null}

                                    {universityDetails.banner_img ? <img src={universityDetails.banner_img} alt="" width={50} height={50} /> : null}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <label for="inputEmail3" className="col-form-label">Logo Image</label>
                                <div >
                                    <input type="file" className="form-control" name="logo_img" id="logo_img" accept='image/*'
                                        onChange={(e) => formik.setFieldValue('logo_img', e.currentTarget.files[0])} onBlur={formik.handleBlur} />
                                    {formik.errors.logo_img && formik.touched.logo_img ? (<span style={{ color: "red" }}>{formik.errors.logo_img}</span>) : null}
                                    {universityDetails.logo_img ? <img src={universityDetails.logo_img} alt="" width={50} height={50} /> : null}
                                </div>

                            </div>
                        </div>
                        <button type="submit" className="btn btn-success">Save</button> <Link to="/university" className="btn btn-outline-light"> Back </Link>
                    </form>
                </div>
            </div>

        </div>
    );
}
export default SaveUniversity;