import React, { useState, useEffect } from "react";
import { Link, Navigate, useNavigate, BrowserRouter as Router, useLocation } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert'; // Import
import { toast } from 'react-toastify';
import API from "../../API";
import MyDataTable from "../../components/MyDataTable";

//const LIMIT = 8;
function Colleges() {
  const location = useLocation();
  const navigate = useNavigate();
  const [colleges, setColleges] = useState([]);
  const [totalColleges, setTotalColleges] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [noImage, setnoImage] = useState('assets/images/no-image.png');
  const [rerender, setRerender] = useState(false); // State to force re-render
 
  useEffect(() => {

    fetchData();
  }, [location]);

  // const fetchData = () => {
  //   setTimeout(() => {
  //   }, 3000);
  //   axios.get(API_URL + "college", {
  //     params: {
  //       page: activePage,
  //       size: LIMIT
  //     }
  //   }).then(({data}) => {
  //     setActivePage(activePage+ 1);
  //     setColleges([...colleges, ...data.records]);
  //     setTotalColleges(data.total)
  //   }).catch(error => {
  //     console.log(error.response);
  //   })
  // }
  const fetchData = async () => {
    try {
      const response = await API.get('college/all');
      setColleges(response.data.records);    
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  


  const Delete = async (id, status) => {
    //fetchData();
    await API({
      method: 'DELETE',
      url: `college/${id}/${status}`,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      //fetchData();
      //setColleges(colleges.filter((item) => item._id !== id));
      toast.success(res.data.message);
      navigate('/colleges');
      //setRerender((prev) => !prev);
    })
  }

  const deleteRecord = (id, status) => {
    console.log(id);
    var StatusMsg = (status == 1) ? 'Inactive' : 'Active';
    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure to ' + StatusMsg + ' this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => Delete(id, status)
        },
        {
          label: 'No',
          //onClick: () => alert('Click No')
        }
      ]
    });
  }

  const columns = [
    {
      name: 'SNO.',
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: 'Banner Image',
      selector: row =>
        <>
          <img src={row?.banner_img ? row.banner_img : noImage} height={50} width={50} />
        </>
    },
    {
      name: 'College Name',
      selector: row => row.college_name,
      sortable: true,
    },
    {
      name: 'Address',
      selector: row => row.address,
      sortable: true,
    },
    {
      name: 'Email',
      selector: row => row.email,
      sortable: true,
    },
    {
      name: 'Action',
      selector: row =>
        <div style={{display:"flex"}}>
          <Link className="form-control"  to={`/college/edit/${row._id}`}>Edit</Link>&nbsp;&nbsp;

          {/* <button  onClick={() => deleteRecord(row._id)}>Delete</button> */}
          {row.is_deleted == 1 ? (<button className="form-control" style={{ backgroundColor: "red" }} onClick={() => deleteRecord(row._id, 0)}>Active</button>) : (<button className="form-control" onClick={() => deleteRecord(row._id, 1)}>Deactive</button>)}
        </div>




    },
  ];
  return (
    <div className="row mt-3">
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className=" text-end"><h5 className="card-title"><Link to="/college/add">Add New</Link></h5></div>
            </div>

            <div className="table-responsive">
            <MyDataTable
                title="College List" data={colleges} columns={columns}
              />             
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Colleges;