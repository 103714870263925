import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import Select from 'react-select';
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import makeAnimated from 'react-select/animated';
import { CollegeFormSchema } from "../validation/CollegeFormSchema";
import { toast } from 'react-toastify';
import API from "../../API";

const animatedComponents = makeAnimated();
function SaveColleges() {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const [collegeDetail, setCollegeDetails] = useState({});
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [district, setDistrict] = useState([]);
  const [university, setUniversity] = useState([]);
  const [undertaking, setUndertaking] = useState([]);
  const [courseType, setCourseType] = useState([]);
  const [course, setCourse] = useState([]);
  const [collegeTypes, setCollegeTypes] = useState([]);
  const [selectedOptionCollegeType, setSelectedOptionCollegeType] = useState('');
  const [selectedOptionCountry, setSelectedOptionCountry] = useState('');
  const [selectedOptionState, setSelectedOptionState] = useState('');
  const [selectedOptionCity, setSelectedOptionCity] = useState('');
  const [selectedOptionDistrict, setSelectedOptionDistrict] = useState('');
  const [selectedOptionCourseType, setSelectedOptionCourseType] = useState([]);
  const [selectedOptionCourse, setSelectedOptionCourse] = useState([]);
  const [selectedOptionUndertaking, setSelectedOptionUndertaking] = useState('');
  const [selectedOptionUniversity, setSelectedOptionUniversity] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);
  const [courseTypeErr, setCourseTypeErr] = useState(false);
  const [courseErr, setCourseErr] = useState(false);



  useEffect(() => {
    fetchCollegeData();
    //fetchCourseType();
    fetchCountries();
    fetchUniversity();
    fetchUndertaking();
    fetchCollegeTypes();

    if (!params.id) {
      fetchCourseType();
      fetchCourse();
    }

    //getValue();

    //getStates(collegeDetail.college_type?._id);
    //getStates("6666cfb8bb0147ab8abbfce2");
  }, [])
  // if(!params.id){
  //   fetchCourseType();
  // }
  let id = params.id
  // const fetchCollegeData = async () => {
  //   try {
  //     setTimeout(() => {
  //     }, 3000);
  //     const response = await axios.get(`http://localhost:4000/college/${params.id}`);

  //     setCollegeDetails(response.data.records);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // };

  const fetchCollegeData = () => {
    setTimeout(() => {
    }, 3000);
    API.get(`college/${params.id}`, {
      //   params: {
      //     id: params.id
      //   }
    }).then(({ data }) => {
      setCollegeDetails(data.records);
      if (data.records.college_type?._id) {
        setSelectedOptionCollegeType(data.records.college_type?._id);
      }
      if (data.records.undertaking?._id) {
        setSelectedOptionUndertaking(data.records.undertaking?._id);
      }
      if (data.records.affiliation?._id) {
        setSelectedOptionUniversity(data.records.affiliation?._id);
      }
      if (data.records.country?._id) {
        getStates(data.records.country?._id);
        setSelectedOptionCountry(data.records.country?._id);
      }
      if (data.records.state?._id) {
        getCities(data.records.state?._id);
        getDistrict(data.records.state?._id);
        setSelectedOptionState(data.records.state?._id);
      }
      if (data.records.city?._id) {
        setSelectedOptionCity(data.records.city?._id);
      }
      if (data.records.district?._id) {
        setSelectedOptionDistrict(data.records.district?._id);
      }
      // if (data.records.course_type?._id) {
      //     setSelectedOptionCourseType(data.records.course_type?._id);
      // }
      if (data.records.course_type) {
        const courseTypeArray = data.records.course_type?.split(',');
        setSelectedOption(courseTypeArray);
      }

      fetchCourseType(data.records.course_type);
      fetchCourse(data.records.course);


    }).catch(error => {
      console.log(error.dat);
    })
  }
  const fetchCourseType = async (param) => {
    try {
      const response = await API.get('course-type');

      let courseTypeOption = response.data.records?.map(item => {
        let properties = {
          "value": item._id,
          "label": item.course_type_name
        };
        return properties;
      });
      setCourseType(courseTypeOption);



      if (param) {
        const courseTypeArray = param?.split(',');
        const selectedcourseTypeOptionValue = courseTypeOption.filter(filterByID);
        function filterByID(item) {
          if (courseTypeArray?.includes(item.value)) {
            return true;
          }
        }

        setSelectedOptionCourseType(selectedcourseTypeOptionValue);
      }

    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };
  const fetchCourse = async (param) => {
    try {
      const response = await API.get('course');

      let courseOption = response.data.records?.map(item => {
        let properties = {
          "value": item._id,
          "label": item.course_name
        };
        return properties;
      });
      setCourse(courseOption);



      if (param) {
        const courseArray = param?.split(',');
        const selectedcourseOptionValue = courseOption.filter(filterByID);
        function filterByID(item) {
          if (courseArray?.includes(item.value)) {
            return true;
          }
        }
        setSelectedOptionCourse(selectedcourseOptionValue);
      }

    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const fetchCountries = async () => {
    try {
      const response = await API.get('country/all');
      setCountry(response.data.records);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };
  const getStates = async (country_id) => {
    try {
      const response = await API.get(`state/getStates/${country_id}`);
      setState(response.data.records);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const getCities = async (state_id) => {
    try {
      const response = await API.get(`city/getCities/${state_id}`);
      setCity(response.data.records);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };
  const getDistrict = async (state_id) => {
    try {
      const response = await API.get(`district/getDistrict/${state_id}`);
      setDistrict(response.data.records);
    } catch (error) {
      console.error('Error fetching :', error);
    }
  };

  const fetchUniversity = async () => {
    try {
      const response = await API.get('university/all');
      setUniversity(response.data.records);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const fetchUndertaking = async () => {
    try {
      const response = await API.get('undertaking');
      setUndertaking(response.data.records);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const fetchCollegeTypes = async () => {
    try {
      const response = await API.get('collegetypes');
      setCollegeTypes(response.data.records);

    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleChangeCollegeType = (event) => {
    setSelectedOptionCollegeType(event.target.value);
  };
  const handleChangeUndertaking = (event) => {
    setSelectedOptionUndertaking(event.target.value);
  };
  const handleChangeUniversity = (event) => {
    setSelectedOptionUniversity(event.target.value);
  };
  const handleChangeCountry = (event) => {
    setSelectedOptionCountry(event.target.value);
  };
  const handleChangeState = (event) => {
    setSelectedOptionState(event.target.value);
  };
  const handleChangeCity = (event) => {
    setSelectedOptionCity(event.target.value);
  };
  const handleChangeDistrict = (event) => {
    setSelectedOptionDistrict(event.target.value);
  };


  const formInitialValue = {
    banner_img: null,
    banner_img_previous: collegeDetail.banner_img ? collegeDetail.banner_img : '',
    logo_img: null,
    logo_img_previous: collegeDetail.logo_img ? collegeDetail.logo_img : '',
    college_name: collegeDetail.college_name ? collegeDetail.college_name : '',
    short_name: collegeDetail.short_name ? collegeDetail.short_name : '',
    college_code: collegeDetail.college_code ? collegeDetail.college_code : '',
    other_name: collegeDetail.other_name ? collegeDetail.other_name : '',
    eshtablish: collegeDetail.eshtablish ? collegeDetail.eshtablish : '',
    college_type: collegeDetail.college_type?._id ? collegeDetail.college_type?._id : '',
    undertaking: collegeDetail.undertaking?._id ? collegeDetail.undertaking?._id : '',
    affiliation: collegeDetail.affiliation?._id ? collegeDetail.affiliation?._id : '',
    accreditation: collegeDetail.accreditation ? collegeDetail.accreditation : '',
    principal_name: collegeDetail.principal_name ? collegeDetail.principal_name : '',

    address: collegeDetail.address ? collegeDetail.address : '',
    landmark: collegeDetail.landmark ? collegeDetail.landmark : '',
    city: collegeDetail.city?._id ? collegeDetail.city?._id : '',
    district: collegeDetail.district?._id ? collegeDetail.district?._id : '',
    state: collegeDetail.state?._id ? collegeDetail.state?._id : '',
    country: collegeDetail.country?._id ? collegeDetail.country?._id : '',

    email: collegeDetail.email ? collegeDetail.email : '',
    email2: collegeDetail.email2 ? collegeDetail.email2 : '',
    phone: collegeDetail.phone ? collegeDetail.phone : '',
    website_url: collegeDetail.website_url ? collegeDetail.website_url : '',
    website_display: collegeDetail.website_display ? collegeDetail.website_display : '',
    course: collegeDetail.course?._id ? collegeDetail.course?._id : '',
    course_type: collegeDetail.course_type?._id ? collegeDetail.course_type?._id : ''
  }
  //console.log(collegeDetail.college_name);
  const formik = useFormik({
    initialValues: formInitialValue,
    validationSchema: CollegeFormSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log(selectedOptionCourse)
      if (selectedOptionCourseType.length == 0) {
        setCourseTypeErr(true);
        return false;
      }
      if (selectedOptionCourse.length == 0) {
        setCourseErr(true);
        return false;
      }
      const collegeData = new FormData();
      for (let value in values) {
        if (value == 'course_type') {
          var courseTypeValues = Array.prototype.map.call(selectedOptionCourseType, function (item) { return item.value; }).join(",");
          collegeData.append('course_type', courseTypeValues);
          // console.log(courseTypeValues)
        }
        else if (value == 'course') {
          var courseValues = Array.prototype.map.call(selectedOptionCourse, function (item) { return item.value; }).join(",");
          collegeData.append('course', courseValues);

        } else {
          collegeData.append(value, values[value]);
        }



        //console.log(value, values[value] );
      }

      if (params.id) {
        API.put(`college/${params.id}`,
          collegeData).then((res) => {
            toast.success(res.data.message);
            navigate('/colleges');
          }).catch((error) => {
            console.log(error);
            toast.error("Something went wrong");
          });

      } else {
        API.post("college",
          collegeData).then((res) => {
            //alert("data inserted successfully");
            toast.success(res.data.message);
            navigate('/colleges');
          }).catch((error) => {
            console.log(error);
            toast.error("Something went wrong");
          });
      }
    }
  });








  return (
    <div className="row mt-3">
      <div className="card mb-3">
        <div className="card-header">
          <h5 className="card-title">{params.id ? 'Edit ' : 'Add '} College</h5>
        </div>
        <div className="card-body">
          <form onSubmit={formik.handleSubmit} encType='multipart/form-data'>
          <h5 className="border-bottom text-danger" style={{ fontFamily: 'Rubik' }}><i className="fa fa-file-text-o"></i> Basic Info</h5>
            <div className="row mb-3">
              <div className="col-sm-6">
                <label for="inputEmail3" className="col-form-label">College Name <span className="text-danger">*</span></label>
                <div>
                  <input type="text" className="form-control" name="college_name" id="college_name" placeholder="Eg: Lady Brabourne College" value={formik.values.college_name} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                  {formik.errors.college_name && formik.touched.college_name ? (<span style={{ color: "red" }}>{formik.errors.college_name}</span>) : null}
                </div>
              </div>
              <div className="col-sm-6">
                <label for="inputEmail3" className="col-form-label">College Short Name</label>
                <div >
                  <input type="text" className="form-control" name="short_name" id="short_name" placeholder="Eg: Lady Brabourne College" onBlur={formik.handleBlur} value={formik.values.short_name} onChange={formik.handleChange} />
                  {formik.errors.short_name && formik.touched.short_name ? (<span style={{ color: "red" }}>{formik.errors.short_name}</span>) : null}
                </div>

              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-6">
                <label for="inputEmail3" className="col-form-label">College Code </label>
                <div>
                  <input type="text" className="form-control" name="college_code" id="college_code" placeholder="Eg: 012" onBlur={formik.handleBlur} value={formik.values.college_code} onChange={formik.handleChange} />
                  {formik.errors.college_code && formik.touched.college_code ? (<span style={{ color: "red" }}>{formik.errors.college_code}</span>) : null}
                </div>
              </div>
              <div className="col-sm-6">
                <label for="inputEmail3" className="col-form-label">Other / Former Name</label>
                <div >
                  <input type="text" className="form-control" name="other_name" id="other_name" placeholder="Eg: South Suburban College" onBlur={formik.handleBlur} value={formik.values.other_name} onChange={formik.handleChange} />
                  {formik.errors.other_name && formik.touched.other_name ? (<span style={{ color: "red" }}>{formik.errors.other_name}</span>) : null}
                </div>

              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-6">
                <label for="inputEmail3" className="col-form-label">Estd.</label>
                <div>
                  <input type="text" className="form-control" name="eshtablish" id="eshtablish" placeholder="Eg: 1916" onBlur={formik.handleBlur} value={formik.values.eshtablish} onChange={formik.handleChange} />
                  {formik.errors.eshtablish && formik.touched.eshtablish ? (<span style={{ color: "red" }}>{formik.errors.eshtablish}</span>) : null}
                </div>
              </div>
              <div className="col-sm-6">
                <label for="inputEmail3" className="col-form-label">College Type</label>
                <div >
                  <select name="college_type" id="college_type" className="form-select" onBlur={formik.handleBlur} onChange={(e) => {
                    handleChangeCollegeType(e);
                    formik.handleChange(e);
                  }} value={selectedOptionCollegeType}>
                    <option value="">Select</option>
                    {Array.isArray(collegeTypes) && collegeTypes?.map((item, index) =>
                      <option key={index} value={item._id}>{item.college_type_name}</option>
                    )}

                  </select>
                  {formik.errors.college_type && formik.touched.college_type ? (<span style={{ color: "red" }}>{formik.errors.college_type}</span>) : null}
                </div>

              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-6">
                <label for="inputEmail3" className="col-form-label">Undertaking</label>
                <div>
                  <select name="undertaking" id="undertaking" className="form-select" onBlur={formik.handleBlur} onChange={(e) => {
                    handleChangeUndertaking(e);
                    formik.handleChange(e);
                  }} value={selectedOptionUndertaking}>
                    <option value="">Select</option>
                    {Array.isArray(undertaking) && undertaking?.map((item, index) =>
                      <option key={index} value={item._id}>{item.undertaking_name}</option>
                    )}
                  </select>
                  {formik.errors.undertaking && formik.touched.undertaking ? (<span style={{ color: "red" }}>{formik.errors.undertaking}</span>) : null}
                </div>
              </div>
              <div className="col-sm-6">
                <label for="inputEmail3" className="col-form-label">Affiliation<span className="text-danger">*</span></label>
                <div >
                  <select name="affiliation" id="affiliation" className="form-select" onBlur={formik.handleBlur} onChange={(e) => {
                    handleChangeUniversity(e);
                    formik.handleChange(e);
                  }} value={selectedOptionUniversity}>
                    <option value="">Select</option>
                    {Array.isArray(university) && university?.map((item, index) =>
                      <option key={index} value={item._id}>{item.university_name}</option>
                    )}
                  </select>
                  {formik.errors.affiliation && formik.touched.affiliation ? (<span style={{ color: "red" }}>{formik.errors.affiliation}</span>) : null}
                </div>

              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-6">
                <label for="inputEmail3" className="col-form-label">Accreditation</label>
                <div>
                  <input type="text" className="form-control" name="accreditation" id="accreditation" placeholder="Eg: NAAC, AICTE" onBlur={formik.handleBlur} value={formik.values.accreditation} onChange={formik.handleChange} />
                  {formik.errors.accreditation && formik.touched.accreditation ? (<span style={{ color: "red" }}>{formik.errors.accreditation}</span>) : null}
                </div>
              </div>
              <div className="col-sm-6">
                <label for="inputEmail3" className="col-form-label">Principal / TIC</label>
                <div >
                  <input type="text" className="form-control" name="principal_name" id="principal_name" placeholder="Eg: Dr Dipak Kumar" onBlur={formik.handleBlur} value={formik.values.principal_name} onChange={formik.handleChange} />
                  {formik.errors.principal_name && formik.touched.principal_name ? (<span style={{ color: "red" }}>{formik.errors.principal_name}</span>) : null}
                </div>

              </div>
            </div>
            <h5 className="border-bottom text-danger" style={{ fontFamily: 'Rubik' }}><i className="fa fa-globe"></i> Contact Info</h5>
            <div className="row mb-3">
              <div className="col-sm-6">
                <label for="inputEmail3" className="col-form-label">Address<span className="text-danger">*</span></label>
                <div>
                  <input type="text" className="form-control" name="address" id="address" placeholder="Address" onBlur={formik.handleBlur} value={formik.values.address} onChange={formik.handleChange} />
                  {formik.errors.address && formik.touched.address ? (<span style={{ color: "red" }}>{formik.errors.address}</span>) : null}
                </div>
              </div>
              <div className="col-sm-6">
                <label for="inputEmail3" className="col-form-label">Landmark</label>
                <div >
                  <input type="text" className="form-control" name="landmark" id="landmark" placeholder="Eg: Near SBI Bank" onBlur={formik.handleBlur} value={formik.values.landmark} onChange={formik.handleChange} />
                  {formik.errors.landmark && formik.touched.landmark ? (<span style={{ color: "red" }}>{formik.errors.landmark}</span>) : null}
                </div>

              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-6">
                <label for="inputEmail3" className="col-form-label">Country<span className="text-danger">*</span></label>
                <div>
                  <select name="country" id="country" className="form-select" onBlur={formik.handleBlur} onChange={(e) => {
                    formik.handleChange(e);
                    handleChangeCountry(e);
                    getStates(e.currentTarget.value);
                  }} value={selectedOptionCountry}>
                    <option value="">Select</option>
                    {country.map((item, index) =>
                      <option key={index} value={item._id}>{item.country_name}</option>
                    )}
                  </select>
                  {formik.errors.country && formik.touched.country ? (<span style={{ color: "red" }}>{formik.errors.country}</span>) : null}
                </div>
              </div>
              <div className="col-sm-6">
                <label for="inputEmail3" className="col-form-label">State<span className="text-danger">*</span></label>
                <div >
                  <select name="state" id="state" className="form-select" value={selectedOptionState} onBlur={formik.handleBlur} onChange={(e) => {
                    formik.handleChange(e);

                    getCities(e.currentTarget.value);
                    getDistrict(e.currentTarget.value);
                    handleChangeState(e);
                  }}>
                    <option value="">Select</option>
                    {Array.isArray(state) && state?.map((item, index) =>
                      <option key={index} value={item._id}>{item.state_name}</option>
                    )}
                  </select>
                  {formik.errors.state && formik.touched.state ? (<span style={{ color: "red" }}>{formik.errors.state}</span>) : null}
                </div>

              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-6">
                <label for="inputEmail3" className="col-form-label">City / Town<span className="text-danger">*</span></label>
                <div>
                  <select name="city" id="city" className="form-select" onBlur={formik.handleBlur} value={selectedOptionCity} onChange={(e) => {
                    formik.handleChange(e);
                    handleChangeCity(e);
                  }}>
                    <option value="">Select</option>
                    {Array.isArray(city) && city?.map((item, index) =>
                      <option key={index} value={item._id}>{item.city_name}</option>
                    )}
                  </select>
                  {formik.errors.city && formik.touched.city ? (<span style={{ color: "red" }}>{formik.errors.city}</span>) : null}
                </div>
              </div>
              <div className="col-sm-6">
                <label for="inputEmail3" className="col-form-label">District<span className="text-danger">*</span></label>
                <div >
                  <select name="district" id="district" className="form-select" onBlur={formik.handleBlur} onChange={(e) => {
                    formik.handleChange(e);
                    handleChangeDistrict(e);
                  }} value={selectedOptionDistrict}>
                    <option value="">Select</option>
                    {Array.isArray(district) && district?.map((item, index) =>
                      <option key={index} value={item._id}>{item.district_name}</option>
                    )}
                  </select>
                  {formik.errors.district && formik.touched.district ? (<span style={{ color: "red" }}>{formik.errors.district}</span>) : null}
                </div>

              </div>
            </div>
            <h5 className="border-bottom text-danger" style={{ fontFamily: 'Rubik' }}><i className="fa fa-globe"></i> Social Info</h5>

            <div className="row mb-3">
              <div className="col-sm-6">
                <label for="inputEmail3" className="col-form-label">E-mail Id 1<span className="text-danger">*</span></label>
                <div>
                  <input type="text" className="form-control" name="email" id="email" placeholder="Enter College Email Address" onBlur={formik.handleBlur} value={formik.values.email} onChange={formik.handleChange} />
                  {formik.errors.email && formik.touched.email ? (<span style={{ color: "red" }}>{formik.errors.email}</span>) : null}
                </div>
              </div>
              <div className="col-sm-6">
                <label for="inputEmail3" className="col-form-label">E-mail Id 2</label>
                <div >
                  <input type="text" className="form-control" name="email2" id="email2" placeholder="Enter Email Id" onBlur={formik.handleBlur} value={formik.values.email2} onChange={formik.handleChange} />
                  {formik.errors.email2 && formik.touched.email2 ? (<span style={{ color: "red" }}>{formik.errors.email2}</span>) : null}
                </div>

              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-6">
                <label for="inputEmail3" className="col-form-label">Phone</label>
                <div>
                  <input type="text" className="form-control" name="phone" id="phone" placeholder="Enter College Phone Number" onBlur={formik.handleBlur} value={formik.values.phone} onChange={formik.handleChange} />
                  {formik.errors.phone && formik.touched.phone ? (<span style={{ color: "red" }}>{formik.errors.phone}</span>) : null}
                </div>
              </div>
              <div className="col-sm-6">
                <label for="inputEmail3" className="col-form-label">Website URL</label>
                <div >
                  <input type="text" className="form-control" name="website_url" id="website_url" placeholder="http(s)://www.abc.com" onBlur={formik.handleBlur} value={formik.values.website_url} onChange={formik.handleChange} />
                  {formik.errors.website_url && formik.touched.website_url ? (<span style={{ color: "red" }}>{formik.errors.website_url}</span>) : null}
                </div>

              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-6">
                <label for="inputEmail3" className="col-form-label">Website Display</label>
                <div>
                  <input type="text" className="form-control" name="website_display" id="website_display" placeholder="www.abc.com" onBlur={formik.handleBlur} value={formik.values.website_display} onChange={formik.handleChange} />
                  {formik.errors.website_display && formik.touched.website_display ? (<span style={{ color: "red" }}>{formik.errors.website_display}</span>) : null}
                </div>
              </div>
             
            </div>
            <h5 className="border-bottom text-danger" style={{ fontFamily: 'Rubik' }}><i className="fa fa-book"></i> Course Info <small>(For Listing Purpose only)</small></h5>
            <div className="row mb-3">
              <div className="col-sm-6">
                <label for="inputEmail3" className="col-form-label">Course Type</label>
                <div>
                  <Select
                    name="course_type"
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    // defaultValue={ selectedcourseTypeOptionValue.map(ele => ele) }
                    defaultValue={selectedOptionCourseType}
                    isMulti
                    //value={getValue()}
                    styles={{
                      singleValue: (base) => ({ ...base, color: 'black' }),
                      valueContainer: (base) => ({
                        ...base,
                        background: '#1a2e60',
                        color: 'red',
                        width: '100%',
                        border: 'red',
                      }),
                      placeholder: (base) => ({
                        ...base,
                        fontSize: '1em',
                        color: '#c5cad7',
                        fontWeight: 400,
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        fontWeight: state.isSelected ? "bold" : "normal",
                        color: 'black',
                        backgroundColor: 'white'
                      }),
                    }}
                    value={selectedOptionCourseType}
                    options={courseType}
                    onChange={setSelectedOptionCourseType}

                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.course_type && formik.touched.course_type ? (<span style={{ color: "red" }}>{formik.errors.course_type}</span>) : null}

                  {courseTypeErr ? (<span style={{ color: "red" }}>Select atleast one</span>) : null}
                </div>
              </div>
              <div className="col-sm-6">
                <label for="inputEmail3" className="col-form-label">Course Name</label>
                <div >
                  <Select
                    name="course"
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    // defaultValue={ selectedcourseTypeOptionValue.map(ele => ele) }
                    defaultValue={selectedOptionCourse}
                    isMulti
                    //value={getValue()}
                    styles={{
                      singleValue: (base) => ({ ...base, color: 'black' }),
                      valueContainer: (base) => ({
                        ...base,
                        background: '#1a2e60',
                        color: 'red',
                        width: '100%',
                      }),
                      placeholder: (base) => ({
                        ...base,
                        fontSize: '1em',
                        color: '#c5cad7',
                        fontWeight: 400,
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        fontWeight: state.isSelected ? "bold" : "normal",
                        color: 'black',
                        backgroundColor: 'white'
                      }),
                    }}
                    value={selectedOptionCourse}
                    options={course}
                    onChange={setSelectedOptionCourse}

                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.course && formik.touched.course ? (<span style={{ color: "red" }}>{formik.errors.course}</span>) : null}

                  {courseErr ? (<span style={{ color: "red" }}>Select atleast one</span>) : null}
                </div>

              </div>
            </div>

            <h5 className="border-bottom text-danger" style={{ fontFamily: 'Rubik' }}><i className="fa fa-book"></i> Images </h5>
            <div className="row mb-3">
              <div className="col-sm-6">
                <label for="inputEmail3" className="col-form-label">Banner Image</label>
                <div>
                  <input type="file" className="form-control" name="banner_img" id="banner_img" accept='image/*'
                    onChange={(e) => formik.setFieldValue('banner_img', e.currentTarget.files[0])} onBlur={formik.handleBlur} />
                  {collegeDetail.banner_img ? <img src={collegeDetail.banner_img} alt="" width={50} height={50} /> : null}
                  {formik.errors.banner_img && formik.touched.banner_img ? (<span style={{ color: "red" }}>{formik.errors.banner_img}</span>) : null}
                </div>
              </div>
              <div className="col-sm-6">
                <label for="inputEmail3" className="col-form-label">Logo Image</label>
                <div >
                  <input type="file" className="form-control" name="logo_img" id="logo_img" accept='image/*'
                    onChange={(e) => formik.setFieldValue('logo_img', e.currentTarget.files[0])} onBlur={formik.handleBlur} />
                  {collegeDetail.logo_img ? <img src={collegeDetail.logo_img} alt="" width={50} height={50} /> : null}
                  {formik.errors.logo_img && formik.touched.logo_img ? (<span style={{ color: "red" }}>{formik.errors.logo_img}</span>) : null}
                </div>

              </div>
            </div>
            <button type="submit" className="btn btn-success">Save</button> <Link to="/colleges" className="btn btn-outline-light"> Back </Link>
          </form>
        </div>
      </div>

    </div>
  );

}
export default SaveColleges;