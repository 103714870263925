import * as Yup from 'yup';

import React from 'react'

export const CityFormSchema = Yup.object({ 
    country_id:Yup.string().required("Select Country"),
    state_id:Yup.string().required("Select State"),
    city_name:Yup.string().required("Enter city name"),
    short_name:Yup.string().required("Enter short name")
    
});