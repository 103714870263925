import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { CollegeNoticeFormSchema } from "../validation/CollegeNoticeFormSchema";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import API from "../../API";


function SaveCollegeNotice() {
    const params = useParams();
    const navigate = useNavigate();
    const [noticeDetails, setNoticeDetails] = useState([]);
    const [colleges, setColleges] = useState([]);
    const [courseType, setCourseType] = useState([]);
    const [noticeType, setNoticeType] = useState([]);
    const [selectedOptionCollege, setSelectedOptionCollege] = useState('');
    const [selectedOptionCourseType, setSelectedOptionCourseType] = useState('');
    const [selectedOptionNoticeType, setSelectedOptionNoticeType] = useState('');
    useEffect(() => {
        fetchNoticeData();
        fetchColleges();
        fetchCourseType();
        fetchNoticeTypes();
    }, []);
    const fetchNoticeData = () => {
        setTimeout(() => {
        }, 3000);
        API.get(`college-notice/${params.id}`, {
            //   params: {
            //     id: params.id
            //   }
        }).then(({ data }) => {
            setNoticeDetails(data.records);

            if (data.records.college_id?._id) {
                setSelectedOptionCollege(data.records.college_id?._id);
            }
            if (data.records.course_type_id?._id) {
                setSelectedOptionCourseType(data.records.course_type_id?._id);
            }
            if (data.records.notice_type_id?._id) {
                setSelectedOptionNoticeType(data.records.notice_type_id?._id);
            }

        }).catch(error => {
            console.log(error.dat);
        })
    }
    const fetchColleges = async () => {
        try {
            const response = await API.get('college/all');
            setColleges(response.data.records);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };
    const fetchCourseType = async () => {
        try {
            const response = await API.get('course-type');
            setCourseType(response.data.records);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };
    const fetchNoticeTypes = async () => {
        try {
            const response = await API.get('notice-type');
            setNoticeType(response.data.records);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };
    const formInitialValue = {
        college_name: noticeDetails.college_id?._id ? noticeDetails.college_id?._id : '',
        course_type: noticeDetails.course_type_id?._id ? noticeDetails.course_type_id?._id : '',
        notice_type: noticeDetails.notice_type_id?._id ? noticeDetails.notice_type_id?._id : '',
        notice_date: noticeDetails.notice_date ? noticeDetails.notice_date : '',
        notice_title: noticeDetails.notice_title ? noticeDetails.notice_title : '',
        full_url_link: noticeDetails.full_url_link ? noticeDetails.full_url_link : '',
        active_status: noticeDetails.active_status ? noticeDetails.active_status : 'active',
        new_tag: noticeDetails.new_tag ? noticeDetails.new_tag : 'yes'
    }
    const formik = useFormik({
        initialValues: formInitialValue,
        validationSchema: CollegeNoticeFormSchema,
        enableReinitialize: true,
        onSubmit: (values) => {


            const noticeData = {
                college_name: values.college_name,
                course_type: values.course_type,
                notice_type: values.notice_type,
                notice_date: values.notice_date,
                notice_title: values.notice_title,
                full_url_link: values.full_url_link,
                active_status: values.active_status,
                new_tag: values.new_tag
            };


            if (params.id) {
                API.put(`college-notice/${params.id}`,
                    noticeData).then((res) => {
                        toast.success(res.data.message);
                        navigate('/colleges-notice');
                    }).catch((error) => {
                        console.log(error);
                        toast.error("Something went wrong");
                    });

            } else {
                API.post("college-notice",
                    noticeData).then((res) => {
                        //alert("data inserted successfully");
                        toast.success(res.data.message);
                        navigate('/colleges-notice');
                    }).catch((error) => {
                        console.log(error);
                        toast.error("Something went wrong");
                    });
            }

        }
    });


    const handleChangeCollege = (event) => {
        setSelectedOptionCollege(event.target.value);
    };
    const handleChangeCourseType = (event) => {
        setSelectedOptionCourseType(event.target.value);
    };
    const handleChangeNoticeType = (event) => {
        setSelectedOptionNoticeType(event.target.value);
    };





    return (
        <div className="row mt-3">
            <div className="card mb-3">
                <div className="card-header">
                    <h5 className="card-title">{params.id ? 'Edit ' : 'Add '} College Notice</h5>
                </div>
                <div className="card-body">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row mb-3">
                            <div className="col-sm-6">
                                <label for="inputEmail3" className="col-form-label">College Name <span className="text-danger">*</span></label>
                                <div>
                                    <select name="college_name" id="college_name" className="form-control" onBlur={formik.handleBlur} onChange={(e) => {
                                        formik.handleChange(e);
                                        handleChangeCollege(e);
                                    }} value={selectedOptionCollege} >
                                        <option value="">Select</option>
                                        {colleges?.map((item, index) =>
                                            <option value={item._id}>{item.college_name}</option>
                                        )}
                                    </select>

                                    {formik.errors.college_name && formik.touched.college_name ? (<span style={{ color: "red" }}>{formik.errors.college_name}</span>) : null}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <label for="inputEmail3" className="col-form-label">Course Type<span className="text-danger">*</span></label>
                                <div >
                                    <select name="course_type" id="course_type" className="form-control" onBlur={formik.handleBlur} onChange={(e) => {
                                        formik.handleChange(e);
                                        handleChangeCourseType(e);
                                    }} value={selectedOptionCourseType}>
                                        <option value="">Select</option>
                                        {courseType?.map((item, index) =>
                                            <option value={item._id}>{item.course_type_name}</option>
                                        )}
                                    </select>
                                    {formik.errors.course_type && formik.touched.course_type ? (<span style={{ color: "red" }}>{formik.errors.course_type}</span>) : null}
                                </div>

                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-sm-6">
                                <label for="inputEmail3" className="col-form-label">Notice Type <span className="text-danger">*</span></label>
                                <div>
                                    <select name="notice_type" id="notice_type" className="form-control" onBlur={formik.handleBlur} onChange={(e) => {
                                        formik.handleChange(e);
                                        handleChangeNoticeType(e);
                                    }} value={selectedOptionNoticeType}>
                                        <option value="">Select</option>
                                        {noticeType?.map((item, index) =>
                                            <option value={item._id}>{item.notice_type_name}</option>
                                        )}
                                    </select>

                                    {formik.errors.notice_type && formik.touched.notice_type ? (<span style={{ color: "red" }}>{formik.errors.notice_type}</span>) : null}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <label for="inputEmail3" className="col-form-label">Notice Date<span className="text-danger">*</span></label>
                                <div >
                                    <input type="date" name="notice_date" id="notice_date" className="form-control" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.notice_date} />
                                    {formik.errors.notice_date && formik.touched.notice_date ? (<span style={{ color: "red" }}>{formik.errors.notice_date}</span>) : null}
                                </div>

                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-sm-6">
                                <label for="inputEmail3" className="col-form-label">Notice Title<span className="text-danger">*</span></label>
                                <div>
                                    <input type="text" name="notice_title" id="notice_title" className="form-control" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.notice_title} placeholder="Title for Notice" />

                                    {formik.errors.notice_title && formik.touched.notice_title ? (<span style={{ color: "red" }}>{formik.errors.notice_title}</span>) : null}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <label for="inputEmail3" className="col-form-label">Full URL Link<span className="text-danger">*</span></label>
                                <div >
                                    <input type="text" name="full_url_link" id="full_url_link" className="form-control" onBlur={formik.handleBlur} onChange={formik.handleChange} placeholder="https://www.collegeadmision.in/XXX/XXXX.php" value={formik.values.full_url_link} />
                                    {formik.errors.full_url_link && formik.touched.full_url_link ? (<span style={{ color: "red" }}>{formik.errors.full_url_link}</span>) : null}
                                </div>

                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-sm-6">
                                <label for="inputEmail3" className="col-form-label">Active Status<span className="text-danger">*</span></label>
                                <div>
                                    <select name="active_status" id="active_status" className="form-control" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.active_status}>
                                        <option value="active">Active</option>
                                        <option value="inactive">In-Active</option>
                                    </select>
                                    {formik.errors.active_status && formik.touched.active_status ? (<span style={{ color: "red" }}>{formik.errors.active_status}</span>) : null}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <label for="inputEmail3" className="col-form-label">New Tag<span className="text-danger">*</span></label>
                                <div >
                                    <select name="new_tag" id="new_tag" className="form-control" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.new_tag}>
                                        <option value="yes">Yes</option>
                                        <option value="no">No</option>
                                    </select>
                                    {formik.errors.new_tag && formik.touched.new_tag ? (<span style={{ color: "red" }}>{formik.errors.new_tag}</span>) : null}
                                </div>

                            </div>
                        </div>


                        <button type="submit" className="btn btn-success">Save</button> <Link to="/colleges-notice" className="btn btn-outline-light"> Back </Link>
                    </form>
                </div>
            </div>

        </div>
    );
}
export default SaveCollegeNotice;