
import * as Yup from 'yup';
import React from 'react'

const MAX_FILE_SIZE = 102400; //100KB

const validFileExtensions = { image: ['jpg', 'gif', 'png', 'jpeg', 'svg', 'webp'] };

function isValidFileType(fileName, fileType) {
  return fileName && validFileExtensions[fileType].indexOf(fileName.split('.').pop()) > -1;
}
export const CollegeFormSchema = Yup.object({
    banner_img: Yup
    .mixed().notRequired()
    // .test("is-valid-type", "Not a valid image type",
    //   value => isValidFileType(value && value.name?.toLowerCase(), "image"))
      .test('fileFormat', 'Only "jpg, gif, png, jpeg, svg, webp" files are allowed', value => {
        if (value) {
          const supportedFormats = ['jpg', 'gif', 'png', 'jpeg', 'svg', 'webp'];
          return supportedFormats.includes(value.name?.split('.').pop());
        }
        return true;
      })
    //.test("is-valid-size", "Max allowed size is 100KB",
    //  value => value && value.size <= MAX_FILE_SIZE),
    .test('fileSize', 'File size must not be more than 3MB', 
      value => {
        if (value) {
          return value.size <= 3145728;
        }
        return true;
      }),
      logo_img: Yup
      .mixed().notRequired()
        .test('fileFormat', 'Only "jpg, gif, png, jpeg, svg, webp" files are allowed', value => {
          if (value) {
            const supportedFormats = ['jpg', 'gif', 'png', 'jpeg', 'svg', 'webp'];
            return supportedFormats.includes(value.name?.split('.').pop());
          }
          return true;
        })
      .test('fileSize', 'File size must not be more than 3MB', 
        value => {
          if (value) {
            return value.size <= 3145728;
          }
          return true;
        }),
    college_name:Yup.string().min(3).max(60).required("Enter college name"),
    short_name:Yup.string().min(2).max(10),
    college_code:Yup.string().min(2).max(10),
    other_name:Yup.string().min(3).max(60),
    eshtablish:Yup.number().positive().integer(),
    college_type:Yup.string(),
    undertaking:Yup.string(),
    affiliation:Yup.string().required("Enter affiliation"),
    accreditation:Yup.string().min(2).max(10),
    principal_name:Yup.string().min(3).max(60),
    address:Yup.string().min(3).max(60).required("Enter address"),
    landmark:Yup.string().min(3).max(50),
    city:Yup.string().required("Select city name"),
    district:Yup.string().required("Select district name"),
    state:Yup.string().required("Select state name"),
    country:Yup.string().required("Select country name"),
    email:Yup.string().min(3).max(35).email().required("enter email address"),
    email2:Yup.string().min(3).max(35).email(),
    phone:Yup.string().min(10).max(10),
    website_url:Yup.string().url().nullable(),
    website_display:Yup.string().url().nullable(),
   // course_type:Yup.string().required("Select course type"),
});