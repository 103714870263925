import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { CourseFormSchema } from "../validation/CourseFormSchema";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import API from "../../API";


function SaveCourse() {
    const params = useParams();
    const navigate = useNavigate();
    const [courseDetails, setCourseDetails] = useState([]);
    useEffect(() => {
        fetchCourseTypeData();
    }, []);

    const fetchCourseTypeData = () => {
        setTimeout(() => {
        }, 3000);
        API.get(`course/${params.id}`, {
            //   params: {
            //     id: params.id
            //   }
        }).then(({ data }) => {
            setCourseDetails(data.records);
        }).catch(error => {
            console.log(error.dat);
        })
    }

    const formInitialValue = {
        course_name: courseDetails.course_name ? courseDetails.course_name : '',
        short_name: courseDetails.short_name ? courseDetails.short_name : '',
    }
    const formik = useFormik({
        initialValues: formInitialValue,
        validationSchema: CourseFormSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            const courseData = {
                course_name: values.course_name,
                short_name: values.short_name
            };

            if (params.id) {
                API.put(`course/${params.id}`,
                    courseData).then((res) => {
                        toast.success(res.data.message);
                        navigate('/course');
                    }).catch((error) => {
                        console.log(error);
                        toast.error("Something went wrong");
                    });

            } else {
                API.post("course",
                    courseData).then((res) => {
                        //alert("data inserted successfully");
                        toast.success(res.data.message);
                        navigate('/course');
                    }).catch((error) => {
                        console.log(error);
                        toast.error("Something went wrong");
                    });
            }

        }
    });








    return (
        <div className="row mt-3">
            <div className="card mb-3">
                <div className="card-header">
                    <h5 className="card-title">{params.id ? 'Edit ' : 'Add '} Course Name</h5>
                </div>
                <div className="card-body">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row mb-3">
                            <label for="inputEmail3" className="col-sm-3 col-form-label">Course Name <span className="text-danger">*</span></label>
                            <div className="col-sm-9">
                                <input type="text" name="course_name" id="course_name" className="form-control" onBlur={formik.handleBlur} onChange={formik.handleChange} placeholder="Course Type Name" value={formik.values.course_name} />
                                {formik.errors.course_name && formik.touched.course_name ? (<span style={{ color: "red" }}>{formik.errors.course_name}</span>) : null}
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label for="inputEmail3" className="col-sm-3 col-form-label">Short Name<span className="text-danger">*</span></label>
                            <div className="col-sm-9">
                                <input type="text" name="short_name" id="short_name" className="form-control" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.short_name} placeholder="Short Name" />
                                {formik.errors.short_name && formik.touched.short_name ? (<span style={{ color: "red" }}>{formik.errors.short_name}</span>) : null}
                            </div>
                        </div>

                        <button type="submit" className="btn btn-success">Save</button> <Link to="/course" className="btn btn-outline-light"> Back </Link>
                    </form>
                </div>
            </div>

        </div>
    );
}
export default SaveCourse;