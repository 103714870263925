import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { CountryFormSchema } from "../validation/CountryFormSchema";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import API from "../../API";
function SaveCountry() {
    const params = useParams();
    const navigate = useNavigate();
    const [countryDetails, setCountryDetails] = useState([]);
    useEffect(() => {
        fetchCountryData();
    }, []);

    const fetchCountryData = () => {
        setTimeout(() => {
        }, 3000);
        API.get(`country/${params.id}`, {
            //   params: {
            //     id: params.id
            //   }
        }).then(({ data }) => {
            setCountryDetails(data.records);
        }).catch(error => {
            console.log(error.dat);
        })
    }
    const formInitialValue = {
        country_name: countryDetails.country_name ? countryDetails.country_name : '',
        short_name: countryDetails.short_name ? countryDetails.short_name : ''
    }
    const formik = useFormik({
        initialValues: formInitialValue,
        validationSchema: CountryFormSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            const countryData = {
                country_name: values.country_name,
                short_name: values.short_name
            };
            if (params.id) {
                API.put(`country/${params.id}`,
                    countryData).then((res) => {
                        toast.success(res.data.message);
                        navigate('/country');
                    }).catch((error) => {
                        console.log(error);
                        toast.error("Something went wrong");
                    });

            } else {
                API.post("country",
                    countryData).then((res) => {
                        //alert("data inserted successfully");
                        toast.success(res.data.message);
                        navigate('/country');
                    }).catch((error) => {
                        console.log(error);
                        toast.error("Something went wrong");
                    });
            }
        }
    });








    return (
        <div className="row mt-3">
            <div className="card mb-3">
                <div className="card-header">
                    <h5 className="card-title">{params.id ? 'Edit ' : 'Add '} Country</h5>
                </div>
                <div className="card-body">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row mb-3">
                            <label for="inputEmail3" className="col-sm-3 col-form-label">Country Name</label>
                            <div className="col-sm-9">
                            <input type="text"  name="country_name" id="country_name" className="form-control" onBlur={formik.handleBlur} onChange={formik.handleChange} placeholder="Enter Country Name" value={formik.values.country_name} />
                            {formik.errors.country_name && formik.touched.country_name?(<span style={{color:"red"}}>{formik.errors.country_name}</span>):null}
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label for="inputPassword3" className="col-sm-3 col-form-label">Short Name</label>
                            <div className="col-sm-9">
                            <input type="text"  name="short_name" id="short_name" className="form-control" onBlur={formik.handleBlur} onChange={formik.handleChange} placeholder="Enter Country Short Name" value={formik.values.short_name} />
                            {formik.errors.short_name && formik.touched.short_name?(<span style={{color:"red"}}>{formik.errors.short_name}</span>):null}
                            </div>
                        </div>

                        <button type="submit" className="btn btn-success">Save</button> <Link to="/country" className="btn btn-outline-light"> Back </Link>
                    </form>
                </div>
            </div>

        </div>
    );
}
export default SaveCountry;